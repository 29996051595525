<template>
  <div class="content" v-if="userData">
    <the-header v-if="userData" :userData="userData"></the-header>
    <Toast />
    <div class="content_block">
      <div class="widget widget_orders">
        <div class="widget_title">Мои заказы</div>
        <div class="orders_list">
          <div
            class="orders_list_item"
            v-for="order in this.orders"
            :key="order.id"
          >
            <div class="orders_list_item_wrapper">
              <div class="order_image">
                <img
                  v-if="order.photo"
                  :src="
                    this.$store.state.rootPath + '/catalog_images/' + order.photo
                  "
                  :alt="order.name"
                />
                <img
                  v-else
                  :src="
                    this.$store.state.rootPath + '/catalog_images/no_photo.jpg'
                  "
                  :alt="order.name"
                />
              </div>
              <div class="order_info">
                <router-link :to="'/catalog/' + order.product_id" class="h1">{{
                  order.name
                }}</router-link>
                <div class="description" v-if="order.description">
                  {{ order.description }}
                </div>
                <div class="conditions" v-if="order.conditions">
                  <p class="h3">Условия предоставления</p>
                  {{ order.conditions }}
                </div>
                <div class="controls">
                  <div class="price">
                    <span>{{ order.price }}</span>
                  </div>
                  <div class="dates">
                    <p>
                      Дата покупки: <span>{{ order.date }}</span>
                    </p>
                    <p v-if="order.expire_date">
                      Дата окончания: <span>{{ order.expire_date }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../data/UserService";

export default {
  data() {
    return {
      userData: null,
      orders: null
    }
  },
  UserService: null,
  created() {
    this.UserService = new UserService();
  },
  mounted() {
    this.checkAuthorization();
  },
  methods: {
    checkAuthorization() {
      const that = this;
      this.UserService.checkAuth().then(data => {
        if (data.status) {
          that.userData = data.message;
          that.updateOrdersList(that.userData);
        } else {
          that.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
          that.$router.push('/auth');
        }
      })
    },
    updateOrdersList(user_data) {
      const formData = new FormData();
      let that = this;
      formData.append('action', 'getUserOrders');
      formData.append('user_id', user_data.id);
      formData.append('token', localStorage.starta_token);
      formData.append('c_user_id', user_data.id);


      fetch(this.$store.state.rootPath + "/actions/orders.php", {
          method: "POST",
          body: formData,
        })
        .then((response) => response.json())
        .then(function (data) {
          if (data.status) {
            that.orders = data.message;
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.widget_orders {
  .orders_list {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
    &_item {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      margin-bottom: 35px;
      border-bottom: 1px #e1e1e1 solid;
      padding-bottom: 35px;
      &_wrapper {
        display: flex;
        flex-wrap: nowrap;
        .order_image {
          flex: 0 0 25%;
          width: 25%;
          max-width: 278px;
          img {
            margin: 0 auto;
            display: block;
            max-width: 278px;
            width: 100%;
            object-fit: contain;
          }
        }
        .order_info {
          flex: 0 0 75%;
          width: 75%;
          max-width: 75%;
          padding-left: 30px;
          .h1 {
            margin-bottom: 10px;
            font-size: 28px;
            font-weight: 500;
            display: table;
            text-decoration: none;
          }
          .h3 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 5px;
            display: table;
          }
          .description,
          .conditions {
            line-height: 1.3;
            margin-bottom: 15px;
            display: table;
          }
          .controls {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            justify-content: flex-start;
            .price {
              display: flex;
              width: 60px;
              height: 60px;
              align-items: center;
              justify-content: center;
              background: #392977;
              border-radius: 100px;
              margin-right: 28px;
              span {
                font-size: 22px;
                font-weight: 500;
                color: white;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                &:after {
                  content: "";
                  width: 12px;
                  height: 17px;
                  background: url("../assets/img/s_coin.svg") no-repeat center;
                  background-size: contain;
                  display: table;
                  filter: brightness(10);
                  margin-left: 2px;
                }
              }
            }
            .dates {
              p {
                font-size: 18px;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
      &:last-of-type {
          border-bottom: none;
          margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .orders_list {
      &_item {
        &_wrapper {
          flex-wrap: wrap;
          .order_image {
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
            margin-bottom: 20px;
            img {
              max-width: 100%;
            }
          }
          .order_info {
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
            padding-left: 0;
            .h1 {
              font-size: 22px;
            }
            .h3 {
              font-size: 16px;
            }
            .description,
            .conditions {
              font-size: 14px;
            }
            .controls {
              flex-wrap: wrap;
              .price {
                margin-bottom: 10px;
              }
              .dates {
                p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>