<template>
  <Toast />
  <router-view></router-view>

  <div id="modals"></div>


</template>

<script>
import UserService from "./data/UserService";

export default {
  data() {
    return {
      userData: null
    }
  },
  UserService: null,
  created() {
    this.UserService = new UserService();
  },
  mounted() {
    this.checkAuthorization();
  },
  methods: {
    checkAuthorization() {
      const that = this;
      this.UserService.checkAuth().then(data => {
        if (data.status) {
          that.userData = data.message;
        } else {
          that.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
          that.$router.push('/auth');
        }
      })
    }
  },
  watch: {
    $route(to) {
      document.title = to.meta.title + ' | Старта.Маркет' || 'Старта.Маркет';
    },
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600&family=Raleway&display=swap');

* {
  box-sizing: border-box;
  font-family: "Exo 2", Arial, sans-serif;

  &:focus,
  &:active,
  &:hover {
    outline: none;
  }
}

a {
  color: #392977;
}

p,
h1,
h2 {
  margin: 0;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Raleway", Arial, sans-serif;
  font-feature-settings: "pnum" on, "lnum" on;
  overflow-x: hidden;
  input,
  select {
    border-radius: 0px;
  }
}

.p-avatar {
  img {
    object-fit: cover;
  }
}



.admin_topbar a {
  text-decoration: none;
}

body {
  background-color: #F7F7F8;
  padding-top: 35px;
  padding-bottom: 30px;

  .content {
    width: 100%;
    max-width: 1670px;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;

    &_block {
      margin-top: 30px;
      width: 100%;

      &_title {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      &_subtitle {
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 5px;
      }
    }
  }

  h1.widget_title {
    margin-bottom: 15px;
    padding-left: 15px;
  }

  .widget {
    border-radius: 20px;
    background-color: white;
    padding: 15px 25px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 50px;

    &_title {
      font-size: 24px;
      font-weight: 500;
      color: #828282;
    }

    &_preloader {
      display: flex;
      align-items: center;
      min-height: 300px;
      justify-content: center;

      .preloader {
        width: 180px;
        height: 180px;
        display: block;
        background: url('./assets/img/preloader.gif') no-repeat center;
        background-size: contain;
      }
    }
  }

  .grid {
    display: grid;
  }

  .button {
    border: 1px #4f4f4f solid;
    height: 37px;
    line-height: 37px;
    border-radius: 25px;
    padding: 0 10px;
    display: table;
    font-size: 16px;
    text-decoration: none;
    margin: 0;
    font-size: 16px;
    color: black;
    padding: 0 17px;
    transition: 0.1s;
    cursor: pointer;

    &:hover {
      background: #CBD5FB;
    }
  }

  @media screen and (max-width: 1370px) {
    padding-top: 25px;
    padding-bottom: 25px;

    .content {
      padding-left: 25px;
      padding-right: 25px;

      &_block {
        margin-top: 20px;

        &_title {
          font-size: 26px;
          margin-bottom: 10px;
        }
      }
    }

    .widget {
      padding: 20px 28px;
      margin-bottom: 35px;

      &_title {
        font-size: 22px;
      }
    }

    .button {
      height: 35px;
      line-height: 35px;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 680px) {
    padding-top: 15px;
    padding-bottom: 15px;

    .content {
      padding-left: 15px;
      padding-right: 15px;
    }

    .widget {
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 400px) {
    .widget {
      padding: 15px 20px;
    }
  }
}
</style>
