<template>
    <div class="content" v-if="userData">
        <the-header v-if="userData" :userData="userData"></the-header>
        <div class="content_block content_block_admin_users" v-if="showAdminControls">
        <div class="admin_topbar flex p-5">
            <router-link to="/admin/users" class="mr-2">
            <Button label="Пользователи" icon="pi pi-user" class="p-button" />
            </router-link>
            <router-link to="/admin/transactions" class="mr-2">
            <Button label="Операции" icon="pi pi-sort-alt" class="p-button" />
            </router-link>
        </div>
        
            <div>
            
            <div class="widget">
                <h1 class="widget_title">Все пользователи</h1>
                <DataTable 
                    :value="users" 
                    :paginator="true" 
                    :rows="10" 
                    responsiveLayout="stack" 
                    breakpoint="1200px" 
                    sortField="name" 
                    :sortOrder="1" 
                    contextMenu 
                    v-model:contextMenuSelection="selectedTableUser" @rowContextmenu="onRowContextMenu">
                    <template #header>
                        <div class="flex justify-content-between">
                            <div class="controls flex">
                                <Button label="Добавить пользователя" icon="pi pi-plus-circle" class="p-button-sm" @click="openUserModal" />
                            </div>
                        </div>
                    </template>
                    <Column field="id" header="ID" :sortable="true"></Column>
                    <Column field="name" header="Имя сотрудника" :sortable="true" class="user_col">
                        <template #body="{data}">
                            <router-link :to="'/profile/' + data.id">
                                <Avatar :image="this.$store.state.rootPath + '/users_data/' + data.image" class="mr-2" shape="circle" style="vertical-align: middle;" v-if="data.image" />
                                <Avatar :image="this.$store.state.rootPath + '/users_data/no_photo.jpg'" class="mr-2" shape="circle" style="vertical-align: middle;" v-else/>
                                <span class="image-text" style="margin-left: 10px">{{data.name}}</span>
                            </router-link>
                        </template>
                    </Column>
                    <Column field="position" header="Должность"></Column>
                    <Column field="birthdate" header="Дата рождения" :sortable="true"></Column>
                    <Column field="email" header="E-mail"></Column>
                    <Column field="phone" header="Телефон"></Column>
                    <Column field="permissions" header="Группа" :sortable="true"></Column>
                    <Column field="balance" header="Баланс" :sortable="true"></Column>
                </DataTable>

                <ContextMenu :model="userContextMenu" ref="cm" />
                
            </div>
            <Dialog header="Новый пользователь" v-model:visible="displayUserModal" class="user_modal">
                <form ref="userCreateForm">
                    <div class="flex flex-wrap">
                        <div class="field flex-auto pr-3">
                            <label for="firstname">Имя</label>
                            <InputText id="firstname" name="firstname" v-model="enteredFirstName" placeholder="Введите имя" class="w-full"/>
                        </div>
                        <div class="field flex-auto pr-3">
                            <label for="lastname">Фамилия</label>
                            <InputText id="lastname" name="lastname" v-model="enteredLastName" placeholder="Введите фамилию" class="w-full"/>
                        </div>
                        <div class="field flex-auto pr-3">
                            <label for="email">E-mail</label>
                            <InputText id="email" name="email" v-model="enteredEmail" placeholder="Адрес почты"  class="w-full"/>
                        </div>
                        <div class="field flex-auto pr-3">
                            <label for="position">Должность</label>
                            <InputText id="position" name="position" v-model="enteredPosition" placeholder="Укажите должность" class="w-full"/>
                        </div>
                        <div class="field flex-auto pr-3">
                            <label for="permissions">Группа пользователя</label>
                            <div class="w-full pr-3">
                                <Dropdown v-model="selectedPermission" name="permissions" id="permissions" :options="permissions" optionLabel="name" optionValue="code" placeholder="Выберите группу"/>
                            </div>
                        </div>
                        <div class="field flex-auto pr-3">
                            <label for="balance">Стартовый баланс</label>
                            <div class="w-full pr-3">
                                <InputNumber id="balance" name="balance" v-model="enteredBalance" showButtons buttonLayout="horizontal" :step="1" :min="0" :max="100" decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"  class="w-full"/>
                            </div>
                        </div>
                    </div>
                </form>
                <template #footer>
                    <Button label="Отмена" icon="pi pi-times" @click="closeUserModal" class="p-button-raised p-button-danger p-button-text"/>
                    <Button label="Добавить" icon="pi pi-check" @click="createUser" class="p-button-raised" autofocus />
                </template>
            </Dialog>            
            </div>

        </div>

        <div class="content_block" v-else>
            <h1 class="content_block_title">401 &ndash; Доступ запрещён</h1>
            <div class="access-denied">
                <p>Для просмотра данной страницы необходимы расширенные права.<br />Если хотите, можете обратиться к администратору, но лучше не надо
                </p>
                <router-link to="/" class="button">Это не справедливо!</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import AdminService from './data/AdminService';
import UserService from "../../data/UserService";

export default {
    data() {
        return {
            users: null,
            selectedTableUser: null,
            userContextMenu: [
                {label: 'Удалить', icon: 'pi pi-fw pi-times', command: () => {
                    this.deleteUser(this.selectedTableUser);
                }
                }
            ],
            displayUserModal: false,
            enteredFirstName: null,
            enteredLastName: null,
            enteredEmail: null,
            enteredPosition: null,
            permissions: [{code: 'admin', name: 'Администратор'},{code: 'user', name: 'Пользователь'}],
            selectedPermission: 'user',
            enteredBalance: 0,
            userData: null,
            showAdminControls: false,
        }
    },
    AdminService: null,
    UserService: null,
    created() {
        this.AdminService = new AdminService();
        this.UserService = new UserService();
    },
    mounted() {
        this.checkAuthorization();
        this.checkPermissions();
        this.getAllUsers();
        
    },
    methods: {
        checkAuthorization() {
            const that = this;
            this.UserService.checkAuth().then(data => {
                if (data.status) {
                that.userData = data.message;
                } else {
                that.$toast.add({
                    severity: 'error',
                    summary: 'Ошибка',
                    detail: data.message,
                    life: 4000
                });
                that.$router.push('/auth');
                }
            })
        },
        checkPermissions() {
            const that = this;
            this.UserService.getUserPermissions().then(data => {
                if (data.status) {
                    if (data.message === 'admin') {
                        that.showAdminControls = true;
                    } else {
                        that.showAdminControls = false;
                    }
                } else {
                that.$toast.add({
                    severity: 'error',
                    summary: 'Ошибка',
                    detail: data.message,
                    life: 4000
                });
                that.showAdminControls = false;
                }
            })
        },
        getAllUsers() {
            const that = this;
            this.AdminService.getUsers().then(data => {
                if (data.status) {
                    this.users = data.message;
                } else {
                    that.$toast.add({
                        severity: 'error',
                        summary: 'Ошибка',
                        detail: data.message,
                        life: 4000
                    });
                }
            });
        },
        onRowContextMenu(event) {
            this.$refs.cm.show(event.originalEvent);
        },
        createUser() {
            if (this.enteredFirstName && this.enteredLastName && this.enteredEmail && this.selectedPermission && this.enteredBalance >= 0) {
                const that = this;
                const formData = new FormData(this.$refs.userCreateForm);
                formData.append("action", "createNewUser");
                formData.append("permissions", this.selectedPermission);
                formData.append("token", localStorage.starta_token);
                formData.append("c_user_id", localStorage.starta_id);

                this.AdminService.createUser(formData).then(data => {
                    if (data.status) {
                        this.getAllUsers();
                        that.closeUserModal();
                        that.$toast.add({
                            severity: 'success',
                            summary: 'Пользователь добавлен',
                            detail: 'Пароль: '+data.message+'',
                            life: 6000
                        });

                    } else {
                        that.$toast.add({
                            severity: 'error',
                            summary: 'Ошибка',
                            detail: data.message,
                            life: 4000
                        });
                    }
                });
            } else {
                this.$toast.add({
                    severity: 'warn',
                    // summary: 'Ошибка',
                    detail: 'Форма заполнена некорректно',
                    life: 4000
                });
            }
        },
        deleteUser(user) {
            // console.log(user);
            this.$confirm.require({
                message: 'Вы уверены, что хотите удалить пользователя?',
                header: 'Удалить пользователя',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const formData = new FormData();
                    formData.append('action', 'deleteUser');
                    formData.append('token', localStorage.starta_token);
                    formData.append('c_user_id', localStorage.starta_id);
                    formData.append('user_id', user.id);
                    formData.append('user_name', user.name);

                    this.AdminService.deleteUser(formData).then(data => {
                        if (data.status) {
                            this.getAllUsers();
                            this.$toast.add({
                                severity: 'success',
                                // summary: 'Пользователь удалён',
                                detail: data.message,
                                life: 4000
                            });
                        } else {
                            this.$toast.add({
                                severity: 'error',
                                summary: 'Ошибка',
                                detail: data.message,
                                life: 4000
                            });
                        }
                    });
                }
            });
        },
        openUserModal() {
            this.displayUserModal = true;
        },
        closeUserModal() {
            this.displayUserModal = false;
        }
    }
}
</script>

<style lang="scss">

    .user_modal {
        width: 95%;
        max-width: 600px;
        .p-dropdown {
            width: 100%;
        }
    }

    td.user_col .p-avatar {
        @media screen and (max-width: 480px) {
            display: none;
        }
    }

    .access-denied {
        p {
            line-height: 1.4;
        }
        .button {
            margin: 20px 0;
            // width: 250px;
        }
    }
</style>