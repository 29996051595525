<template>
  <div class="content" v-if="userData">
    <the-header v-if="userData" :userData="userData"></the-header>
    <Toast />
    <div class="content_block content_block_admin_users" v-if="showAdminControls">
        <div class="admin_topbar flex p-5">
          <router-link to="/admin/users" class="mr-2">
            <Button label="Пользователи" icon="pi pi-user" class="p-button" />
          </router-link>
          <router-link to="/admin/transactions" class="mr-2">
            <Button label="Операции" icon="pi pi-sort-alt" class="p-button" />
          </router-link>
        </div>
    </div>
    <div class="content_block" v-else>
        <h1 class="content_block_title">401 &ndash; Доступ запрещён</h1>
        <div class="access-denied">
            <p>Для просмотра данной страницы необходимы расширенные права.<br />Если хотите, можете обратиться к администратору, но лучше не надо
            </p>
            <router-link to="/" class="button">Это не справедливо!</router-link>
        </div>
    </div>
  </div>

</template>

<script>
import UserService from "../data/UserService";

export default {
  data() {
    return {
      userData: null,
      showAdminControls: false
    }
  },
  UserService: null,
  created() {
    this.UserService = new UserService();
  },
  mounted() {
    this.checkAuthorization();
    this.checkPermissions();
  },
  methods: {
    checkAuthorization() {
      const that = this;
      this.UserService.checkAuth().then(data => {
        if (data.status) {
          that.userData = data.message;
        } else {
          that.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
          that.$router.push('/auth');
        }
      })
    },
    checkPermissions() {
      const that = this;
      this.UserService.getUserPermissions().then(data => {
        if (data.status) {
          if (data.message === 'admin') {
            that.showAdminControls = true;
          } else {
            that.showAdminControls = false;
          }
        } else {
          that.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
          that.showAdminControls = false;
        }
      })
    }
  },
};

</script>

<style lang="scss" scoped>
  .access-denied {
    p {
      line-height: 1.4;
    }
    .button {
      margin: 20px 0;
      // width: 250px;
    }
  }
</style>