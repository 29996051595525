export default class CommonService {
    getCommonOrders() {
		const formData = new FormData();
		formData.append('action', 'getCommonOrders');
		formData.append('token', localStorage.starta_token);
		formData.append('c_user_id', localStorage.starta_id);

		return fetch(process.env.VUE_APP_URL + "/actions/orders.php", {
			method: "POST",
			body: formData
		})
			.then((response) => response.json())
			.then(function (data) {
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			})
    }

    getCommonOrderByID(id) {
		const formData = new FormData();
		formData.append('action', 'getCommonOrderById');
		formData.append('id', id);
		formData.append('token', localStorage.starta_token);
		formData.append('c_user_id', localStorage.starta_id);

		return fetch(process.env.VUE_APP_URL + "/actions/orders.php", {
			method: "POST",
			body: formData
		})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			})
    }

	getDonates(product_id) {
		const formData = new FormData();
		formData.append('action', 'getDonates');
		formData.append('product_id', product_id);
		formData.append('token', localStorage.starta_token);
		formData.append('c_user_id', localStorage.starta_id);

		return fetch(process.env.VUE_APP_URL + "/actions/orders.php", {
			method: "POST",
			body: formData
		})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			})
	}

	checkCommonCost(product_id) {
		const formData = new FormData();
		formData.append('action', 'checkCommonCost');
		formData.append('product_id', product_id);
		formData.append('token', localStorage.starta_token);
		formData.append('c_user_id', localStorage.starta_id);

		return fetch(process.env.VUE_APP_URL + "/actions/orders.php", {
			method: "POST",
			body: formData
		})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			})
	}

    setCommonDonate(product_id, summ) {
		const formData = new FormData();
		formData.append('action', 'setCommonDonate');
		formData.append('product_id', product_id);
		formData.append('summ', summ);
		formData.append('token', localStorage.starta_token);
		formData.append('c_user_id', localStorage.starta_id);

		return fetch(process.env.VUE_APP_URL + "/actions/orders.php", {
			method: "POST",
			body: formData
		})
			.then((response) => response.json())
			.then(function (data) {
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			})
    }

}