<template>
  <ConfirmDialog></ConfirmDialog>
  <div class="mobile_menu" :class="{ closed: !showMobileMenu }" id="mobile_menu">
    <div class="close" @click="showMobileMenu = !showMobileMenu"></div>
    <div class="mobile_menu_list">
      <menu-list></menu-list>
    </div>
  </div>
  <header class="widget">
    <div class="logo">
      <router-link to="/"><img src="@/assets/img/logo.svg" /></router-link>
    </div>
    <nav class="menu">
      <menu-list></menu-list>
      <div class="burger">
        <span data-mobile_menu @click="showMobileMenu = !showMobileMenu"></span>
      </div>
    </nav>
    <div class="profile">
      <div class="links">
        <router-link :to="'/profile'" class="to_profile" v-if="userData">Мой профиль</router-link>
        <a nohref class="exit" @click.prevent="exit">Выйти</a>
      </div>
      <div class="balance" :class="balanceClass" v-html="formatBalance" title="Мой баланс"></div>
      <div class="photo">
        <router-link :to="'/profile'" v-if="userData">
          <img :src="this.$store.state.rootPath + '/users_data/' + userData.photo" :title="userData.name"
            v-if="userData.photo" />
          <img src="/users_data/no_photo.jpg" :title="userData.name" v-else />
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import MenuList from "../ui/MenuList.vue";

export default {
  data() {
    return {
      showMobileMenu: false,
    };
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  components: {
    "menu-list": MenuList,
  },
  methods: {
    exit() {
      const that = this;
      this.$confirm.require({
        message: 'Вы уверены, что хотите выйти?',
        header: 'Выход',
        icon: 'pi pi-question-circle',
        accept: () => {
          localStorage.removeItem('starta_token');
          localStorage.removeItem('starta_id');
          that.$router.push('/auth');
          // that.$store.commit("exitUser");
        }
      });
    },
  },
  computed: {
    formatBalance() {
      return "<span>" + this.userData.balance + "</span>";
    },
    balanceClass() {
      if (this.userData.balance >= 0) {
        return "positive";
      } else {
        return "negative";
      }

    }
  },
  watch: {
    $route() {
      this.showMobileMenu = false;
    },
  },
};
</script>

<style lang="scss">
header.widget {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 0;

  .logo {
    order: 1;

    a {
      display: table;
      width: 100%;
      max-width: 190px;

      img {
        object-fit: contain;
        width: 100%;
      }
    }
  }

  nav.menu {
    order: 2;
    flex: 0 0 60%;
    width: 60%;
    max-width: 100%;
    align-items: stretch;
    height: 100%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: nowrap;
      gap: 35px;
      justify-content: center;
      align-items: center;
      height: 100%;

      li {
        position: relative;
        height: 100%;
        margin-bottom: -38px;

        a {
          font-size: 16px;
          color: #828282;
          position: relative;

          &.router-link-active {
            color: #392977;

            &:before {
              position: absolute;
              content: "";
              // height: 5px;
              width: 100%;
              left: 0;
              right: 0;
              bottom: 0;
              display: table;
              background-color: #392977;
            }
          }

          &.new {
            &::after {
              position: absolute;
              content: 'new';
              color: white;
              display: flex;
              padding: 1px 4px;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              background-color: #E0491C;
              font-size: 9px;
              top: -8px;
              text-transform: uppercase;
              right: -10px;
            }
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .burger {
      display: none;

      span {
        display: table;
        width: 36px;
        height: 36px;
        background: url("../../assets/img/menu-svgrepo-com.svg") no-repeat center;
        background-size: contain;
      }
    }
  }

  .profile {
    order: 3;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    .links {
      a {
        display: table;
        font-size: 16px;
        margin: 0 0 4px auto;
        color: #4e4e4e;
        font-weight: 500;

        &.exit {
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    .balance {
      span {
        font-size: 44px;
        font-weight: 600;
        color: gray;
        position: relative;
        line-height: 1.3;

        &:after {
          position: absolute;
          content: '';
          width: 26px;
          height: 28px;
          display: table;
          background: url('../../assets/img/s_coin.svg') no-repeat center;
          background-size: contain;
          bottom: 10px;
          right: -28px;
        }
      }

      &.positive span {
        color: #E0491C;
      }

      &.negative span {
        color: #000000;
      }
    }

    .photo {
      margin-left: 26px;

      a {
        display: table;

        img {
          width: 63px;
          height: 63px;
          display: block;
          border: 1px #b7b7b7 solid;
          border-radius: 100px;
          display: block;
          object-fit: cover;
        }
      }
    }
  }

  @media screen and (max-width: 1570px) {
    .nav.menu {
      ul {
        li {
          a {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 1270px) {
    .logo {
      flex: 0 0 140px;
      max-width: 140px;
    }

    nav.menu {
      ul {
        li {
          a {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    nav.menu {
      order: 3;
      flex: auto;
      width: auto;
      align-items: center;
      display: flex;

      ul {
        display: none;
      }

      .burger {
        display: table;
        margin-left: 25px;
      }
    }

    .profile {
      order: 2;
      width: 100%;
    }
  }

  @media screen and (max-width: 680px) {
    padding: 15px 20px;

    .logo {
      flex: 0 0 30%;
      width: 30%;
      max-width: 30%;

      a {
        img {
          width: 100%;
        }
      }
    }

    .profile {
      .links {
        a {
          &.to_profile {
            display: none;
          }

          &.exit {
            font-size: 0px;
            color: transparent;
            width: 30px;
            height: 30px;
            margin: 0;
            display: table;
            background: url("../../assets/img/logout-svgrepo-com.svg") no-repeat center;
            background-size: contain;
          }
        }
      }

      .photo {
        a {
          img {
            width: 52px;
            height: 52px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    padding: 5px 15px;

    .logo {
      display: none;
    }

    nav.menu {
      .burger {
        span {
          width: 28px;
          height: 28px;
        }
      }
    }

    .profile {
      justify-content: start;

      .links {
        a {
          &.exit {
            height: 26px;
            width: 26px;
            margin: 0;
          }
        }
      }

      .photo {
        a {
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}

.mobile_menu {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 1001;
  padding: 25px;

  .close {
    position: absolute;
    z-index: 800;
    top: 42px;
    right: 33px;
    height: 40px;
    width: 40px;
    display: block;

    &:after {
      position: absolute;
      content: "";
      display: table;
      cursor: pointer;
      height: 40px;
      width: 40px;
      transition: 0.1s;
      transform-origin: center;
      background: url("../../assets/img/close-svgrepo-com.svg") no-repeat center;
      background-size: contain;
    }

    &:hover {
      &:after {
        opacity: 0.7;
        transform: rotate(90deg);
      }
    }
  }

  &_list {
    margin: 30px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 15px;
        border-bottom: 1px #e1e1e1 solid;

        a {
          font-size: 20px;
          text-decoration: none;
          transition: 0.1s;

          &.router-link-active {
            padding-left: 10px;
            color: gray;
            font-weight: 500;
          }
        }
      }
    }
  }

  &.closed {
    display: none;
  }
}
</style>