<template>
  <div class="content" v-if="userData">
    <the-header v-if="userData" :userData="userData"></the-header>
    <Toast />
    <div class="content_block content_block_profile" v-if="theUser">
      <div class="content_block_profile_photo">
        <div class="avatar" :style="'background-image: url(' + this.$store.state.rootPath + '/users_data/' + theUser.photo + ')'" v-if="theUser.photo" ></div>
        <div class="avatar" :style="'background-image: url(' + this.$store.state.rootPath + '/users_data/no_photo.jpg)'" v-else></div>
      </div>
      <div class="widget content_block_profile_info">
          <div class="wrapper">
              <p class="permissions" v-if="theUser.permissions == 'admin'">Пользователь имеет права администратора</p>
              <p class="name">{{theUser.firstname}} {{theUser.lastname}}</p>
              <p class="position">{{theUser.position}}</p>
              <p class="email">E-mail: <a :href="'mailto:' + theUser.email">{{theUser.email}}</a></p>
              <p class="birthdate" v-if="theUser.birthdate != ''">Дата рождения: {{theUser.birthdate}}</p>
              <p class="phone" v-if="theUser.phone !== ''">Телефон: <a :href="'tel:' + theUser.phone">{{theUser.phone}}</a></p>
              <p class="telegram" v-if="theUser.telegram != ''">Telegram: <a :href="'https://t.me/' + theUser.telegram" target="_blank">@{{theUser.telegram}}</a></p>
              <p class="balance">Баланс: <span>{{theUser.balance}} <img :src="this.$store.state.rootPath + '/img/s_coin.svg'" width="10"></span></p>
          </div>
      </div>
    </div>
    <div class="content_block" v-else>
      <div class="widget widget_preloader">
          <div class="preloader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '../data/UserService';
export default {
    data() {
      return {
        theUser: this.$store.state.users.find(item => item.id == this.$route.params.id),
        userData: null
      }
    },
    UserService: null,
    methods: {
      checkAuthorization() {
        const that = this;
        this.UserService.checkAuth().then(data => {
          console.log(data);
          if (data.status) {
            that.userData = data.message;
          } else {
            that.$toast.add({
              severity: 'error',
              summary: 'Ошибка',
              detail: data.message,
              life: 4000
            });
            that.$router.push('/auth');
          }
        })
      },
      getViewedUserData() {
        this.theUser = this.$store.state.users.find(item => item.id == this.$route.params.id);
        console.log(this.theUser);
      }
    },
    created() {
      this.UserService = new UserService();

      const that = this;
      setTimeout(function() {
        
          that.theUser = that.$store.state.users.find(item => item.id == that.$route.params.id);
      }, 300);
    },
    mounted() {
      this.checkAuthorization();
    },
    // watch: {
    //   '$route': 'getViewedUserData'
    // }
  };
</script>

<style lang="scss" scoped>
.content_block {
  &_profile {
    display: flex;
    &_photo {
      flex: 0 0 20%;
      max-width: 20%;
      width: 20%;
      margin-right: 60px;
      .avatar {
        width: 100%;
        padding-top: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 300px;
        object-fit: cover;
      }
    }
    &_info {
      flex: auto;
        .wrapper {
            display: block;
            position: relative;
            p {
                margin-bottom: 8px;
                &.name {
                    font-size: 28px;
                    margin-bottom: 5px;
                }
                &.position {
                    font-size: 16px;
                    color: gray;
                    margin-bottom: 20px;
                }
                &.permissions {
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: 14px;
                    color: rgb(158, 158, 158);
                    text-transform: lowercase;
                }
            }
        }
    }
  }

  @media screen and (max-width: 1370px) {
    &_profile {
      &_photo {
        flex: 0 0 25%;
        width: 25%;
        max-width: 25%;
        margin-right: 30px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    &_profile {
      &_photo {
        flex: 0 0 20%;
        width: 20%;
        max-width: 20%;
      }
      &_info {
          .wrapper {
              p {
                  &.permissions {
                      position: relative;
                      top: initial;
                      right: initial;
                  }
              }
          }
      }
    }
  }
  @media screen and (max-width: 900px) {
    &_profile {
      flex-wrap: wrap;
      justify-content: center;
      &_photo {
        flex: 0 0 100%;
        width: 100%;
        max-width: 200px;
        margin: 0 auto 30px auto;
      }
      &_info {
          flex: 0 0 100%;
          max-width: 100%;
          widows: 100%;
      }
    }
  }
}
</style>