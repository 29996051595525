<template>
  <ul>
    <!-- <li><router-link to="/">Дашборд</router-link></li> -->
    <li><router-link to="/catalog">Каталог</router-link></li>
    <li><router-link to="/common" class="new">Командные покупки</router-link></li>
    <li><router-link to="/orders">Мои заказы</router-link></li>
    <li><router-link to="/history">Баланс</router-link></li>
    <li><router-link to="/rules">Правила</router-link></li>
    <li v-if="showAdminMenu">
      <router-link to="/admin">Управление</router-link>
    </li>
  </ul>
</template>

<script>
import UserService from "../../data/UserService";

export default {
  data() {
    return {
      showAdminMenu: false
    }
  },
  UserService: null,
  created() {
    this.UserService = new UserService();
  },
  mounted() {
    this.checkPermissions();
  },
  methods: {
    checkPermissions() {
      const that = this;
      this.UserService.getUserPermissions().then(data => {
        if (data.status) {
          if (data.message === 'admin') {
            that.showAdminMenu = true;
          } else {
            that.showAdminMenu = false;
          }
        } else {
          that.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
          that.showAdminMenu = false;
        }
      })
    }
  }
}
</script>
