<template>
  <div class="content" v-if="userData">
    <the-header v-if="userData" :userData="userData"></the-header>
    <Toast />
    <div class="content_block content_block_dashboard">
      <widget-balance :balance="userData.balance"></widget-balance>
      <!-- <widget-dynamic :userData="userData"></widget-dynamic> -->
      <widget-recent :userId="userData.id" :recentCount="3"></widget-recent>
    </div>
    <div class="content_block">
      <p class="content_block_title">Каталог</p>
      <widget-catalog-listing v-if="userData" :userData="userData"></widget-catalog-listing>
    </div>
  </div>
</template>

<script>
import WidgetBalance from "../layout/ui/WidgetBalance.vue";
// import WidgetDynamic from "../layout/ui/WidgetDynamic.vue";
import WidgetRecent from "../layout/ui/WidgetRecent.vue";
import WidgetCatalogListing from "../layout/ui/WidgetCatalogListing.vue";
import UserService from "../data/UserService";

export default {
  data() {
    return {
      userData: null
    }
  },
  UserService: null,
  created() {
    this.UserService = new UserService();
  },
  mounted() {
    this.checkAuthorization();
  },
  components: {
    "widget-balance": WidgetBalance,
    // "widget-dynamic": WidgetDynamic,
    "widget-recent": WidgetRecent,
    "widget-catalog-listing": WidgetCatalogListing,
  },
  methods: {
    checkAuthorization() {
      const that = this;
      this.UserService.checkAuth().then(data => {
        if (data.status) {
          that.userData = data.message;
        } else {
          that.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
          that.$router.push('/auth');
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.content_block_dashboard {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  gap: 20px 20px;
  grid-template-areas:
      "balance dynamic"
      "recent dynamic";
  > .widget {
    margin: 0;
  }
  .widget_balance {
    grid-area: balance;
  }
  .widget_dynamic {
    grid-area: dynamic;
  }

  @media screen and (max-width: 1370px) {
    grid-template-columns: 0.85fr 1fr 1fr;
    grid-template-rows: 0.7fr 1.64fr;
    gap: 30px 30px;
  }
  @media screen and (max-width: 1200px) {
    grid-template-columns: 0.38fr 1fr;
    grid-template-rows: 0.7fr 1.64fr;
    gap: 25px 25px;
    grid-template-areas:
      "balance recent recent"
      "dynamic dynamic dynamic";
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: 0.5fr 1fr;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    gap: 15px 15px;
    grid-template-areas:
      "balance"
      "recent"
      "dynamic";
  }
}
</style>