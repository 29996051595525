<template>
  <div class="widget widget_catalog">
    <div class="widget_catalog_controls">
      <div class="flex" style="width: 100%;">
        <div class="col-2">
          <widget-catalog-add-new v-if="userData.id == 1 || userData.id == 24" :userData="userData"
            @updateProducts="getCatalogProducts"></widget-catalog-add-new>
        </div>
      </div>
      <!-- <div class="widget_catalog_controls_sort">
        <div class="sort_wrapper">
          Сортировка:
          <span
            ref="sortProducts"
            :data-sort_dir="sortDir"
            :data-sort_by="sortBy"
            @click="showSortControls"
            >{{sortTitle}}</span
          >
          <div class="sort_list hidden" ref="sortList">
            <p data-sort_by="id" data-sort_dir="asc" @click="sortProducts">по новизне</p>
            <p data-sort_by="id" data-sort_dir="desc" @click="sortProducts">по новизне</p>
            <p data-sort_by="price" data-sort_dir="asc" @click="sortProducts">по стоимости</p>
            <p data-sort_by="price" data-sort_dir="desc" @click="sortProducts">по стоимости</p>
          </div>
        </div>
      </div> -->
    </div>
    <div class="widget_catalog_listing">
      <DataView :value="products" :layout="layout" :paginator="true" :rows="12" :sortOrder="sortOrder"
        :sortField="sortField">
        <template #header>
          <div class="flex">
            <div class="col-6" style="text-align: left">
              <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label" placeholder="Сортировка по цене"
                @change="onSortChange($event)" />
            </div>
            <!-- <div class="col-6" style="text-align: right">
              <DataViewLayoutOptions v-model="layout" />
            </div> -->
          </div>
        </template>

        <!-- <template #list="slotProps">
          <div class="widget_catalog_listing_item">
            <div class="product-list-item">
              <router-link :to="'/catalog/' + slotProps.data.id" class="product-list-item-img">
                <img v-if="slotProps.data.photo"
                  :src="this.$store.state.rootPath + '/catalog_images/' + slotProps.data.photo"
                  :alt="slotProps.data.name" />
                <img v-else src="/catalog_images/no_photo.jpg" :alt="slotProps.data.name" />
              </router-link>
              <div class="product-list-detail">
                <router-link :to="'/catalog/' + slotProps.data.id">
                  <div class="product-name">{{ slotProps.data.name }}</div>
                </router-link>
                <div class="product-description">{{ slotProps.data.description }}</div>
              </div>
              <div class="product-list-action">
                <span class="product-price"><span class="s_coin">{{ slotProps.data.price }}</span></span>
              </div>
            </div>
          </div>
        </template> -->

        <template #grid="slotProps">
          <div class="col-12 md:col-3">
            <div class="product-grid-item card">
              <!-- <div class="product-grid-item-top">
                <div>
                  <i class="pi pi-tag product-category-icon"></i>
                  <span class="product-category">Категория</span>
                </div>
                <span :class="'product-badge status-'">Тег</span>
              </div> -->
              <div class="product-grid-item-content">
                <router-link :to="'/catalog/' + slotProps.data.id">
                  <img v-if="slotProps.data.photo"
                    :src="this.$store.state.rootPath + '/catalog_images/' + slotProps.data.photo"
                    :alt="slotProps.data.name" />
                  <img v-else src="/catalog_images/no_photo.jpg" :alt="slotProps.data.name" />
                </router-link>
                <router-link :to="'/catalog/' + slotProps.data.id">
                  <div class="product-name">{{ slotProps.data.name }}</div>
                </router-link>
                <div class="product-description">{{ slotProps.data.description }}</div>
              </div>
              <div class="product-grid-item-bottom">
                <div class="product-price" v-if="slotProps.data.sale_price != 0">
                  <span class="s_coin sale_price" title="Скидка!!">{{ slotProps.data.sale_price }}</span>
                  <span class="s_coin oldprice">{{ slotProps.data.price }}</span>
                </div>
                <div class="product-price" v-else>
                  <span class="s_coin">{{ slotProps.data.price }}</span>
                </div>
                <router-link :to="'/catalog/' + slotProps.data.id" class="button">Подробнее</router-link>
              </div>
              <div class="product-remove" v-if="userData.id == 1 || userData.id == 24"
                @click="removeProduct(slotProps.data.id, slotProps.data.name)">Удалить</div>
            </div>
          </div>
        </template>

      </DataView>
    </div>
  </div>
</template>


<script>
import CatalogListingAddNewVue from "../../layout/ui/CatalogListingAddNew.vue";
import ProductService from "../../data/ProductService";

export default {
  data() {
    return {
      products: null,
      layout: 'grid',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        { label: 'По убыванию цены', value: '!price' },
        { label: 'По возрастанию цены', value: 'price' }
      ],
      // sortDir: "desc",
      // sortBy: "id",
      // sortTitle: "по новизне"
    };
  },
  components: { "widget-catalog-add-new": CatalogListingAddNewVue },
  props: ['userData'],
  ProductService: null,
  methods: {
    getCatalogProducts() {
      const that = this;
      this.ProductService.getProducts().then(data => {
        if (data.status) {
          that.products = data.message;
        }
      });
    },
    removeProduct(id, name) {
      const that = this;
      if (confirm("Вы уверены, что хотите удалить '" + name + "'?")) {
        const formData = new FormData();
        formData.append('action', 'removeProduct');
        formData.append('token', localStorage.starta_token);
        formData.append('id', id);
        formData.append('c_user_id', that.userData.id);

        fetch(this.$store.state.rootPath + "/actions/catalog.php", {
          method: "POST",
          body: formData
        })
          .then((response) => response.json())
          .then(function (data) {
            if (data.status) {
              //that.$store.commit("updateCatalogProducts");
              that.getCatalogProducts();
            }
          })
          .catch(function (err) {
            console.log("Error: ", err);
          });
      }
    },
    // disableCurrentSortControl() {
    //   let sortListItems = this.$refs.sortList.querySelectorAll('p');
    //   const that = this;
    //   sortListItems.forEach((item) => {
    //     let by = item.getAttribute("data-sort_by");
    //     let dir = item.getAttribute("data-sort_dir");
    //     if (by == that.sortBy && dir == that.sortDir) {
    //       item.setAttribute('disabled', true);
    //     } else {
    //       item.removeAttribute('disabled', true);
    //     }
    //   });
    // },
    // showSortControls() {
    //   this.$refs.sortList.classList.toggle("hidden");

    //   this.disableCurrentSortControl();
    // },
    // sortProducts(e) {
    //   let by = e.target.getAttribute("data-sort_by");
    //   let dir = e.target.getAttribute("data-sort_dir");
    //   let title = e.target.textContent;

    //   this.$store.commit("sortCatalogProducts", { sort_by: by, sort_dir: dir });
    //   this.sortBy = by;
    //   localStorage.setItem("catalog_sort_by", by);
    //   this.sortDir = dir;
    //   localStorage.setItem("catalog_sort_dir", dir);
    //   this.sortTitle = title;
    //   localStorage.setItem("catalog_sort_title", title);

    //   this.$refs.sortList.classList.toggle("hidden");

    //   this.disableCurrentSortControl();
    // },
    onSortChange(event) {
      const value = event.value.value;
      const sortValue = event.value;

      if (value.indexOf('!') === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
        this.sortKey = sortValue;
      }
      else {
        this.sortOrder = 1;
        this.sortField = value;
        this.sortKey = sortValue;
      }
    }

  },
  created() {
    this.ProductService = new ProductService();

    if (localStorage.catalog_sort_dir) {
      this.sortDir = localStorage.catalog_sort_dir;
    }
    if (localStorage.catalog_sort_by) {
      this.sortBy = localStorage.catalog_sort_by;
    }
    if (localStorage.catalog_sort_title) {
      this.sortTitle = localStorage.catalog_sort_title;
    }
    if (localStorage.catalog_sort_by && localStorage.catalog_sort_dir) {
      this.$store.commit("sortCatalogProducts", { sort_by: localStorage.catalog_sort_by, sort_dir: localStorage.catalog_sort_dir });
    }
  },
  mounted() {
    this.getCatalogProducts();
  }
};
</script>

<style lang="scss">
.widget_catalog {
  &_controls {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding: 0 35px 0 15px;

    &_sort {
      text-align: right;

      .sort_wrapper {
        position: relative;

        span {
          color: #4e4e4e;
          border-bottom: 1px #4e4e4e dashed;
          cursor: pointer;
          position: relative;

          &:after {
            position: absolute;
            content: '';
            width: 16px;
            height: 16px;
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            left: calc(100% + 4px);
            top: 50%;
            transform: translateY(-40%);
            opacity: .7;
          }

          &[data-sort_dir="asc"] {
            &:after {
              background-image: url('../../assets/img/down-top-sorting-svgrepo-com.svg');
            }
          }

          &[data-sort_dir="desc"] {
            &:after {
              background-image: url('../../assets/img/top-down-sorting-svgrepo-com.svg');
            }
          }
        }

        >div {
          position: absolute;
          z-index: 100;
          background: white;
          padding: 6px 25px 6px 18px;
          right: -24px;
          display: block;
          box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);

          p {
            position: relative;
            cursor: pointer;
            border-bottom: 1px transparent solid;
            display: table;
            margin: 0 0 3px auto;

            &[disabled] {
              pointer-events: none;
              opacity: .5;
            }

            &:after {
              position: absolute;
              content: '';
              width: 16px;
              height: 16px;
              display: block;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              left: calc(100% + 4px);
              top: 50%;
              transform: translateY(-50%);
              opacity: .7;
            }

            &:hover {
              border-bottom: 1px black solid;
            }

            &[data-sort_dir="asc"] {
              &:after {
                background-image: url('../../assets/img/down-top-sorting-svgrepo-com.svg');
              }
            }

            &[data-sort_dir="desc"] {
              &:after {
                background-image: url('../../assets/img/top-down-sorting-svgrepo-com.svg');
              }
            }
          }

          &.hidden {
            display: none;
          }
        }
      }
    }
  }

  &_listing {
    padding: 26px 15px;
    display: grid;
    gap: 35px 35px;
    grid-template-columns: repeat(auto-fit, minmax(272px, 1fr));
    width: 100%;

    .p-dataview {
      .p-dataview-header {
        background-color: transparent;
        border: none;
        padding: 10px 0;
      }
    }

    .p-selectbutton {
      box-shadow: none;
    }
  }

  @media screen and (max-width: 1370px) {
    &_controls {
      margin-bottom: 20px;
    }

    &_listing {
      padding: 0;
    }
  }

  @media screen and (max-width: 640px) {
    &_listing {
      grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    }
  }
}

.catalogUpdateDialog {
  position: fixed;
  z-index: 1000;
  width: auto;
  max-width: 640px;
  padding: 30px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  &::backdrop {
    background: rgba(0, 0, 0, 0.7);
  }

  .closeModal {
    width: 22px;
    height: 22px;
    display: table;
    background: url("../../assets/img/close-svgrepo-com.svg") no-repeat center;
    background-size: contain;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  h3 {
    margin: 0 0 20px 0;
    font-size: 26px;
  }

  form {
    >label {
      margin-bottom: 10px;

      >span {
        font-size: 16px;
        color: #b7b7b7;
        display: table;
        margin-bottom: 4px;
      }

      input,
      textarea {
        border: 1px #afafaf solid;
        font-family: "Raleway", Arial, sans-serif;
        font-feature-settings: "pnum" on, "lnum" on;
        border-radius: 4px;
        font-size: 16px;
        background: transparent;
        padding: 7px 10px;
        color: #4f4f4f;
        width: 100%;
        margin-bottom: 15px;

        &[type="file"] {
          border: none;
          padding: 5px 0;
        }
      }
    }

    input[type="submit"] {
      cursor: pointer;
    }
  }
}

.card {
  background: #ffffff;
  padding: 1rem;
  // box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}

.p-dropdown {
  width: 14rem;
  font-weight: normal;
}

.product-name {
  font-size: 20px;
  line-height: 1.1;
  text-decoration: none;
  margin-bottom: 10px;

}

.product-description {
  margin: 0 0 20px 0;
}

.product-category-icon {
  vertical-align: middle;
  margin-right: .5rem;
}

.product-category {
  font-weight: 600;
  vertical-align: middle;
}

.p-dataview-content {
  .p-grid {
    display: flex;
    flex-wrap: wrap;
  }
}

.product-list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;

  img {
    width: 150px;
    margin-right: 2rem;
    height: 120px;
    object-fit: contain;
  }

  .product-list-detail {
    flex: 1 1 0;
  }

  .p-rating {
    margin: 0 0 .5rem 0;
  }

  // .product-price {
  // 	font-size: 1.5rem;
  // 	font-weight: 600;
  // 	margin-bottom: .5rem;
  // 	align-self: flex-end;
  //   .s_coin {
  //     position: relative;
  //     &:after {
  //       position: absolute;
  //       content: '';
  //       width: 1rem;
  //       height: 1.25rem;
  //       background: url('../../assets/img/s_coin.svg') no-repeat center;
  //       background-size: contain;
  //       display: table;
  //       top: 50%;
  //       left: calc(100% + 3px);
  //       transform: translateY(-50%);
  //     }
  //   }
  // }

  .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .p-button {
    margin-bottom: .5rem;
  }
}

.product-grid-item {
  margin: .5rem;
  border: 1px solid var(--surface-border);

  height: calc(100% - 20px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: space-between;
  gap: 10px;

  .product-grid-item-top,
  .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  img {
    width: 100%;
    height: 230px;
    object-fit: contain;
    margin: 15px auto;
    display: table;
  }

  .product-grid-item-content {
    text-align: center;
    padding: 4px 0;
    width: 100%;

    a {
      text-decoration: none;
    }
  }

  .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    padding-right: 20px;
    display: flex;
    gap: 15px;

    .s_coin {
      position: relative;
      display: flex;
      align-items: center;

      &:after {
        background: url('../../assets/img/s_coin.svg') no-repeat center;
        position: relative;
        content: '';
        width: 1rem;
        height: 1.25rem;
        background-size: contain;
        display: inline-table;
        top: 0;
        left: 0;
        transform: none;
      }

      &.sale_price {
        color: #E0491C;
        font-size: 34px;
        align-items: baseline;

        &:after {
          color: #E0491C;
          width: 1.3rem;
          height: 1.55rem;
        }
      }
    }

    .oldprice {
      color: #747474;
      font-weight: 300;

      &:after {
        opacity: 0.7;
      }

      &:before {
        width: calc(100% + 8px);
        height: 2px;
        display: table;
        left: -4px;
        right: -4px;
        content: '';
        background-color: #747474;
        top: 56%;
        transform: translateY(-50%) rotate(12deg);
        position: absolute;
      }
    }
  }

  .product-remove {
    cursor: pointer;
    border-bottom: 1px #4e4e4e dashed;
  }
}

.p-dataview.p-dataview-list .p-dataview-content>.p-grid {
  gap: 20px;
  justify-content: space-between;

  >.widget_catalog_listing_item {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    max-width: calc(50% - 20px);
    border: 1px solid var(--surface-border);

    .product-list-item {
      display: flex;
      flex-wrap: nowrap;
      gap: 15px;

      &-img {
        flex: 0 0 20%;
        width: 20%;
        min-width: 140px;

        img {
          object-fit: contain;
          width: 140px;
          height: 140px;
          display: block;
        }
      }

      .product-list-detail {
        flex: auto;
      }

      .product-list-action {
        flex: 0 0 15%;
        width: 15%;
        max-width: 15%;
      }

      .product-price {
        align-self: flex-start;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .p-dataview.p-dataview-list .p-dataview-content>.p-grid {

    >.widget_catalog_listing_item {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 576px) {
  .product-list-item {
    flex-direction: column;
    align-items: center;

    img {
      margin: 2rem 0;
    }

    .product-list-detail {
      text-align: center;
    }

    .product-price {
      align-self: center;
    }

    .product-list-action {
      display: flex;
      flex-direction: column;
    }

    .product-list-action {
      margin-top: 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}
</style>