<template>
  <Toast />
  <div class="header">
    <div class="inner-header flex">
      <div class="form_wrapper">
        <!-- <h1>Авторизация</h1> -->
        <div class="logo"></div>
        <div class="widget widget_login">
          <form action="" @submit.prevent="login">
            <label for="email">
              <span>E-mail:</span>
              <input type="email" name="email" id="email" required />
            </label>
            <label for="password">
              <span>Пароль:</span>
              <input type="password" name="password" id="password" required />
            </label>
            <input type="submit" name="send" class="button" vlaue="Войти" />
          </form>
        </div>
      </div>
    </div>

    <div>
      <svg
        class="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shape-rendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g class="parallax">
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="0"
            fill="rgba(255,255,255,0.7"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="3"
            fill="rgba(255,255,255,0.5)"
          />
          <use
            xlink:href="#gentle-wave"
            x="48"
            y="5"
            fill="rgba(255,255,255,0.3)"
          />
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div>
  </div>

  <div class="content flex">
    <p>
       2022&ndash;2024 &copy; <a href="https://starta.ru" target="_blank">Старта</a>
    </p>
  </div>
</template>

<script>
import UserService from "../data/UserService";

export default {
  data() {
    return {
      userData: null
    }
  },
  UserService: null,
  methods: {
    async login(e) {
      const that = this;
      const formData = new FormData(e.target);
      formData.append("action", "authUser");

      await fetch(process.env.VUE_APP_URL + "/actions/users.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then(function (data) {
          if (data.status) {
            localStorage.setItem("starta_token", data.message.token);
            localStorage.setItem("starta_id", data.message.id);
            that.$router.push("/");
          } else {
            that.$toast.add({
              severity: 'error',
              summary: 'Ошибка авторизации',
              detail: data.message,
              life: 4000
            });
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
    checkAuthorization() {
      const that = this;
      this.UserService.checkAuth().then(data => {
        if (data.status) {
          that.$router.push('/');
          // that.userData = data.message;
        } else {
          // that.$toast.add({
          //   severity: 'error',
          //   summary: 'Ошибка',
          //   detail: data.message,
          //   life: 4000
          // });
          // that.$router.push('/auth');
        }
      })
    }
  },
  created() {
    this.UserService = new UserService();
  },
  mounted() {
    this.checkAuthorization();
  }
};
</script>


<style lang="scss" scoped>
.header {
  position: relative;
  text-align: center;
  margin-top: -35px;
  background: linear-gradient(169deg, #392977d4, #e0491c9e 100%);
  color: white;
}
.logo {
  width: 100%;
  filter: invert(1);
  display: block;
  margin: 0 auto 30px auto;
  height: 60px;
  background: url(../assets/img/logo.svg) no-repeat center;
  background-size: contain;
}

.inner-header {
  height: 65vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  max-width: 100%;
  margin-bottom: -30px;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
}

.content_block {
  &_auth {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .content_block_title {
      text-align: center;
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}
.form_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  h1 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
}
.widget_login {
  flex: 0 0 500px;
  width: 500px;
  max-width: 100%;
  form {
    display: flex;
    flex-wrap: wrap;
    > label {
      margin-bottom: 20px;
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      > span {
        font-size: 16px;
        color: #b7b7b7;
        display: table;
      }
      input,
      select {
        border: none;
        border-bottom: 1px #4f4f4f solid;
        font-size: 18px;
        background: transparent;
        padding: 4px 0;
        color: #4f4f4f;
        width: 100%;
        &[type="date"] {
          padding: 4px 0 0 0;
        }
        &[type="file"] {
          border: none;
        }
      }
      &[for="gender"] {
        padding: 0;
        margin: 0;
        padding-top: 3px;
        > span {
          margin-bottom: 5px;
        }
        .radios {
          display: flex;
          .checkbox {
            margin-right: 25px;
            cursor: pointer;
          }
          .checkbox > input {
            position: absolute;
            z-index: -1;
            opacity: 0;
          }

          .checkbox > span {
            display: inline-flex;
            align-items: center;
            user-select: none;
          }

          .checkbox > span::before {
            content: "";
            display: table;
            width: 16px;
            height: 16px;
            background: #e5e5e5;
            border-radius: 20px;
            margin-right: 5px;
          }

          // .checkbox
          //   > input:not(:disabled):not(:checked)
          //   + span:hover::before {
          //   border-color: #392977;
          // }

          .checkbox > input:not(:disabled):active + span::before {
            background-color: #392977;
          }

          // .checkbox > input:focus + span::before {
          //   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          // }

          .checkbox > input:focus:not(:checked) + span::before {
            border-color: #392977;
          }

          .checkbox > input:checked + span::before {
            background-color: #392977;
            width: 16px;
            height: 16px;
            display: table;
            content: "";
          }

          .checkbox > input:disabled + span::before {
            background-color: #e9ecef;
          }
        }
      }
    }
    input[type="submit"] {
    }
  }

  @media screen and (max-width: 640px) {
    flex: 0 0 90%;
    width: 100%;
  }
}
</style>