import {
  createStore
} from 'vuex'
import router from '../router';

const createdStore = createStore({
  state() {
    return {
      isLoggedIn: false,
      balance: 0,
      userId: null,
      userPhoto: null,
      userFirstName: null,
      userLastName: null,
      userEmail: null,
      userPosition: null,
      userBirthdate: null,
      userGender: null,
      userPermissions: null,
      userPhone: null,
      userTelegram: null,

      // rootPath: 'https://cp.starta.ru',
      rootPath: process.env.VUE_APP_URL,

      orders: [],
      transactions: [],
      products: [],
      users: [],
      usersRating: [{data: []}],
      tasks: [{
          id: 1,
          title: "Провести общее обучение сотрудников компании (тренинги по развитию)",
          award: 3,
          condition: "",
          limits: "Не более 1 награды в месяц"
        },
        {
          id: 2,
          title: "Опубликовать статью в корпоративной базе знаний",
          award: 1,
          condition: "НЕ учитываются: статьи-ссылки на другие публикации или внешние ресурсы, а также статьи, содержащие только прикреплённые файлы",
          limits: "Не более 1 награды в месяц"
        },
        {
          id: 3,
          title: "Подготовить и провести обучение для определённого отдела компании",
          award: 2,
          condition: "",
          limits: "Не более 1 награды в месяц"
        },
        {
          id: 4,
          title: "Опубликовать пост на личной странице в соц.сети или мессенджере с указанием аккаунта компании",
          award: 1,
          condition: "Страница должна быть открытой, а пост доступным для других пользователей",
          limits: "Не более 1 награды в месяц"
        },
        {
          id: 5,
          title: "Предложить идею нового продукта компании (новый сервис или услуга)",
          award: 1,
          condition: "Награда предоставляется только если идея пошла в работу",
          limits: "Не более 1 награды в месяц"
        },
        {
          id: 6,
          title: "Предложить идею нового продукта или услуги, которая дошла до релиза",
          award: 10,
          condition: "Награда предоставляется только если идея дошла до релиза и сотрудник принимал участие в её подготовке",
          limits: "Не более 1 награды в месяц"
        },
        {
          id: 7,
          title: "Предложить идею для корпоратива, которую помог организовать (в не рабочее время) и она пошла в релиз (посещаемость 80%+, не чаще 1 раза в 3 месяца)",
          award: 2,
          condition: "Мероприятие должно быть вне офиса. Организацией следует заниматься в нерабочее время. Посещаемость мероприятия должна быть не менее 80% от всего коллектива.",
          limits: "Не более 1 награды за 3 месяца"
        },
        {
          id: 8,
          title: "Разработка нового инструмента, который пошел в работу и дошел до релиза",
          award: 5,
          condition: "",
          limits: ""
        },
        {
          id: 9,
          title: "Участие спикером от лица компании на мероприятии",
          award: 3,
          condition: "",
          limits: ""
        }
      ]
    }
  },
  mutations: {
    // Изменение баланса пользователя в БД
    updateUserBalance(state, payload) {
      const formData = new FormData();
      const token = localStorage.starta_token;
      formData.append("action", "updateUserBalance");
      formData.append("id", payload.user_id);
      formData.append("balance", payload.balance);

      formData.append("token", token);
      formData.append('c_user_id', state.userId);

      fetch(state.rootPath + "/actions/users.php", {
          method: "POST",
          body: formData,
        })
        .then((response) => response.json())
        .then(function (data) {
          // console.log(data);
          if (data.status) {
            alert("Баланс пользователя изменён");
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
    // начисление/списание с баланса текущего пользователя
    setTransaction(state, payload) {
      // изменение баланса во фронте
      state.balance = state.balance - payload.summ;

      // добавление транзакции в БД
      const formData = new FormData();
      formData.append("action", "setTransactionByUserId");
      formData.append("type", "decrease");
      formData.append("count", payload.summ);
      formData.append("comment", "Покупка " + payload.product);
      formData.append("receiver", state.userId);
      formData.append("date", "");
      formData.append("balance", state.balance);

      formData.append('token', localStorage.starta_token);
      formData.append('c_user_id', state.userId);

      fetch(state.rootPath + "/actions/transactions.php", {
          method: "POST",
          body: formData,
        })
        .then((response) => response.json())
        .then(function (data) {
          // console.log(data);
          if (data.status) {
            alert("Отлично! '" + payload.product + "' теперь в твоём распоряжении :)");
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
    // добавление заказа в БД
    createOrder(state, payload) {
      // console.log(payload);
      const formData = new FormData();
      formData.append("action", "createOrder");
      formData.append("product_id", payload.product_id);
      formData.append("expire_date", payload.expire_date);
      formData.append("product_name", payload.product);
      formData.append("user_id", state.userId);
      formData.append("user_name", state.userFirstName + ' ' + state.userLastName + ' (' + state.userEmail + ')');
      formData.append("date", "");

      formData.append('token', localStorage.starta_token);
      formData.append('c_user_id', state.userId);

      fetch(state.rootPath + "/actions/orders.php", {
          method: "POST",
          body: formData,
        })
        .then((response) => response.json())
        .then(function (data) {
          console.log(data);
          // if (data.status) {

          // }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
    // обновление ID пользователя
    updateUserID(state, payload) {
      state.userId = payload.user_id;
      state.isLoggedIn = true;
    },
    // выход из админки
    exitUser(state) {
      state.userId = null;
      state.isLoggedIn = false;
      state.balance = 0;
      state.userPhoto = null;
      state.userFirstName = null;
      state.userLastName = null;
      state.userEmail = null;
      state.userPosition = null;
      state.userBirthdate = null;
      state.userGender = null;
      state.userPermissions = null;
      state.userPhone = null;
      state.userTelegram = null;
      localStorage.removeItem('starta_token');
      localStorage.removeItem('starta_id');
      router.push('/auth');
    },
    // обновление данных пользователя из БД во фронт
    updateUserData(state, payload) {

      // получение данных профиля пользователя из БД
      const formData = new FormData();
      formData.append("action", "getUserDataByID");
      formData.append("id", payload.user_id);
      formData.append('token', localStorage.starta_token);
      formData.append('c_user_id', state.userId);

      fetch(state.rootPath + "/actions/users.php", {
          method: "POST",
          body: formData,
        })
        .then((response) => response.json())
        .then(function (data) {
          if (data.status) {
            state.balance = data.message.balance;
            if (data.message.photo == '') {
              state.userPhoto = state.rootPath + '/users_data/no_photo.jpg';
            } else {
              state.userPhoto = state.rootPath + '/users_data/' + data.message.photo;
            }
            state.userFirstName = data.message.firstname;
            state.userLastName = data.message.lastname;
            state.userEmail = data.message.email;
            state.userPosition = data.message.position;
            state.userBirthdate = data.message.birthdate;
            state.userGender = data.message.gender;
            state.userPermissions = data.message.permissions;
            state.userPhone = data.message.phone;
            state.userTelegram = data.message.telegram;
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });

    },
    // получение транзакций пользователя из БД во фронт
    updateUserTransactions(state, payload) {
      // получение транзакций пользователя из БД
      const formData = new FormData();
      formData.append("action", "getTransactionsByUserId");
      formData.append("id", Number(payload.user_id));
      formData.append("token", localStorage.starta_token);
      formData.append("c_user_id", state.userId);

      fetch(state.rootPath + "/actions/transactions.php", {
          method: "POST",
          body: formData,
        })
        .then((response) => response.json())
        .then(function (data) {
          if (data.status) {
            state.transactions = data.message;
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });

    },
    // сортировка каталога товаров
    sortCatalogProducts(state, payload) {
      function compareById(a, b) {
        if (Number(a.id) > Number(b.id)) return -1;
        if (Number(a.id) < Number(b.id)) return 1;
        return 0;
      }
      function compareByPrice(a, b) {
        if (Number(a.price) > Number(b.price)) return -1;
        if (Number(a.price) < Number(b.price)) return 1;
        return 0;
      }
      switch (payload.sort_by) {
        case 'id':
          state.products.sort(compareById);
          break;
        case 'price':
          state.products.sort(compareByPrice);
          break;
      }
      if (payload.sort_dir == 'desc') {
        state.products.reverse();
      }
    },
    // обновление списка товаров каталога из БД во фронт
    updateCatalogProducts(state) {

      function compareById(a, b) {
        if (Number(a.id) > Number(b.id)) return -1;
        if (Number(a.id) < Number(b.id)) return 1;
        return 0;
      }
      function compareByPrice(a, b) {
        if (Number(a.price) > Number(b.price)) return -1;
        if (Number(a.price) < Number(b.price)) return 1;
        return 0;
      }

      const formData = new FormData();
      formData.append("action", "getCatalogProducts");
      formData.append('token', localStorage.starta_token);
      formData.append('c_user_id', state.userId);

      fetch(state.rootPath + "/actions/catalog.php", {
          method: "POST",
          body: formData,
        })
        .then((response) => response.json())
        .then(function (data) {
          if (data.status) {
            state.products = data.message;

            if (localStorage.catalog_sort_by && localStorage.catalog_sort_dir) {
              switch (localStorage.catalog_sort_by) {
                case 'id':
                  state.products.sort(compareById);
                  break;
                case 'price':
                  state.products.sort(compareByPrice);
                  break;
              }
        
              if (localStorage.catalog_sort_dir == 'desc') {
                state.products.reverse();
              }
            } else {
              state.products.sort(compareById);
              state.products.reverse();
            }
            
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });

    },
    // обновление списка пользователей
    updateUsersList(state) {
      const formData = new FormData();
      formData.append('action', 'getAllUsers');
      formData.append('token', localStorage.starta_token);
      formData.append('c_user_id', state.userId);

      fetch(state.rootPath + "/actions/users.php", {
          method: "POST",
          body: formData,
        })
        .then((response) => response.json())
        .then(function (data) {
          //console.log(data.message);
          if (data.status) {
            state.users = data.message;
            //state.usersRating = [{data: []}];
            let rating = [];

            data.message.forEach(function(item) {
              rating.push({x: item.firstname + ' ' + item.lastname, y: Number(item.balance)});
            });

            state.usersRating = [{data: rating}];
            
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
    // обновление списка заказов
    updateOrdersList(state) {
      const formData = new FormData();

      formData.append('action', 'getUserOrders');
      formData.append('user_id', state.userId);
      formData.append('token', localStorage.starta_token);
      formData.append('c_user_id', state.userId);

      console.log(state);

      fetch(state.rootPath + "/actions/orders.php", {
          method: "POST",
          body: formData,
        })
        .then((response) => response.json())
        .then(function (data) {
          if (data.status) {
            state.orders = data.message;
          }
          console.log(state.orders);
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    }
  },
  actions: {
    // механизм покупки
    // buy(context, payload) {
    //   context.commit("setTransaction", payload);
    //   context.commit("updateUserBalance", {
    //     user_id: context.state.userId,
    //     balance: context.state.balance
    //   });
    //   context.commit("createOrder", payload);
    //   router.push('/orders');
    // },
    // проверка авторизации
    async checkAuth(context) {
      console.log('12312312');
      if (localStorage.starta_token) {
        const formData = new FormData();
        formData.append('action', 'getUserByToken');
        formData.append('token', localStorage.starta_token);
        formData.append('c_user_id', context.state.userId);

        let response = await fetch(context.state.rootPath + "/actions/users.php", {
          method: "POST",
          body: formData,
        });

        response = await response.json();
        console.log(response);
        if (response.status) {
          context.commit("updateUserID", {
            user_id: response.message
          });
          context.commit("updateUserData", {
            user_id: response.message
          });
          context.commit("updateUserTransactions", {
            user_id: response.message
          });
          context.commit("updateCatalogProducts");
          context.commit("updateUsersList");
          context.commit("updateOrdersList");
          context.state.isLoggedIn = true;
        } else {
          router.push('/auth');
        }
        console.log(context.state);

      } else if (!context.state.isLoggedIn) {
        router.push('/auth');
      } else {
        context.commit("updateUserData", {
          user_id: context.state.userId
        });
        context.commit("updateUserTransactions", {
          user_id: context.state.userId
        });
        context.commit("updateCatalogProducts");
        context.commit("updateUsersList");
        context.state.isLoggedIn = true;
      }
    }
  },
  getters: {},
  modules: {}
})


export default createdStore;