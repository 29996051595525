<template>
  <div class="content" v-if="userData">
    <the-header v-if="userData" :userData="userData"></the-header>
    <Toast />
    <div class="content_block content_block_profile">
      <div class="content_block_profile_photo">
        <div class="avatar" :style="'background-image: url(' + $store.state.userPhoto + ')'">
          <!-- <img
            :src="$store.state.userPhoto"
            :alt="$store.state.userFirstName + ' ' + $store.state.userLastName"
          /> -->
        </div>
      </div>
      <div class="widget content_block_profile_fields">
        <form action="" @submit.prevent="saveUserProfile" enctype="multipart/form-data">
          <div class="wrapper">
            <label for="firstname">
              <span>Имя:</span>
              <input type="text" name="firstname" id="firstname" placeholder="Введите имя"
                :value="$store.state.userFirstName" required />
            </label>
            <label for="lastname">
              <span>Фамилия:</span>
              <input type="text" name="lastname" id="lastname" placeholder="Введите фамилию"
                :value="$store.state.userLastName" required />
            </label>
            <label for="position">
              <span>Должность:</span>
              <input type="text" name="position" id="position" placeholder="Чем занимаетесь в компании"
                :value="$store.state.userPosition" />
            </label>
            <label for="email">
              <span>E-mail:</span>
              <input type="email" name="email" id="email" placeholder="Укажите почту" :value="$store.state.userEmail"
                required />
            </label>
            <label for="birthdate">
              <span>Дата рождения:</span>
              <input type="date" name="birthdate" id="birthdate" :value="$store.state.userBirthdate" required />
            </label>
            <label for="phone">
              <span>Номер телефона:</span>
              <input type="tel" name="phone" id="phone" :value="$store.state.userPhone" />
            </label>
            <label for="telegram">
              <span>Telegram:</span>
              <input type="text" name="telegram" id="telegram" :value="$store.state.userTelegram" />
            </label>
            <label for="gender">
              <span>Пол:</span>
              <div class="radios">
                <label class="checkbox">
                  <input type="radio" name="gender[]" value="male"
                    :checked="$store.state.userGender == 'male'" /><span>Мужской</span>
                </label>
                <label class="checkbox">
                  <input type="radio" name="gender[]" value="female"
                    :checked="$store.state.userGender == 'female'" /><span>Женский</span>
                </label>
              </div>
            </label>
            <label for="photo">
              <span>Фото:</span>
              <input type="file" name="photo" id="photo" />
            </label>
          </div>
          <input type="submit" name="send" id="sendProfile" class="button" value="Сохранить" />
        </form>
      </div>
      <div class="widget content_block_profile_password">
        <form action="" @submit.prevent="changeUserPassword">
          <div class="wrapper">
            <label for="password">
              <span>Новый пароль:</span>
              <input type="password" name="password" id="password" placeholder="***********" required
                v-model="password" />
            </label>
            <label for="confirm_password">
              <span>Ещё раз новый пароль:</span>
              <input type="password" name="confirm_password" id="confirm_password" placeholder="***********" required
                v-model="confirm_password" />
            </label>
          </div>
          <input type="submit" name="send" id="changePassword" class="button" value="Изменить пароль" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '../data/UserService';
export default {
  data() {
    return {
      userData: null
    }
  },
  UserService: null,
  mounted() {
    this.checkAuthorization();
  },
  methods: {
    checkAuthorization() {
      const that = this;
      this.UserService.checkAuth().then(data => {
        if (data.status) {
          that.userData = data.message;
          that.getUserData();
        } else {
          that.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
          that.$router.push('/auth');
        }
      })
    },
    saveUserProfile(e) {
      const that = this;
      const formData = new FormData(e.target);
      formData.append("action", "updateUserDataByID");
      formData.append("id", this.userData.id);
      formData.append("token", localStorage.starta_token);
      formData.append("c_user_id", this.userData.id);

      fetch(this.$store.state.rootPath + "/actions/users.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then(function (data) {
          if (data.status) {
            that.$store.commit("updateUserData", { user_id: that.$store.state.userId });
            alert("Профиль сохранен");
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
    changeUserPassword(e) {
      if (this.password == this.confirm_password) {
        // const that = this;
        const formData = new FormData(e.target);
        formData.append("action", "changeUserPassword");
        formData.append("id", this.userData.id);
        formData.append("token", localStorage.starta_token);
        formData.append("c_user_id", this.userData.id);

        fetch(this.$store.state.rootPath + "/actions/users.php", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then(function (data) {
            if (data.status) {
              e.target.reset();
              alert("Пароль изменён");
            }
          })
          .catch(function (err) {
            console.log("Error: ", err);
          });
      } else {
        console.log('Не совпадают пароли!');
      }
    },
    getUserData() {
      console.log(this.userData);
      // получение данных профиля пользователя из БД
      const formData = new FormData();
      let that = this;
      formData.append("action", "getUserDataByID");
      formData.append("id", this.userData.id);
      formData.append('token', localStorage.starta_token);
      formData.append('c_user_id', this.userData.id);

      fetch(this.$store.state.rootPath + "/actions/users.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then(function (data) {
          if (data.status) {
            console.log(data);
            that.$store.state.balance = data.message.balance;
            if (data.message.photo == '') {
              that.$store.state.userPhoto = that.$store.state.rootPath + '/users_data/no_photo.jpg';
            } else {
              that.$store.state.userPhoto = that.$store.state.rootPath + '/users_data/' + data.message.photo;
            }
            that.$store.state.userFirstName = data.message.firstname;
            that.$store.state.userLastName = data.message.lastname;
            that.$store.state.userEmail = data.message.email;
            that.$store.state.userPosition = data.message.position;
            that.$store.state.userBirthdate = data.message.birthdate;
            that.$store.state.userGender = data.message.gender;
            that.$store.state.userPermissions = data.message.permissions;
            that.$store.state.userPhone = data.message.phone;
            that.$store.state.userTelegram = data.message.telegram;
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });

    },
  },
  created() {
    this.UserService = new UserService();
  },
};
</script>

<style lang="scss" scoped>
.content_block {
  &_profile {
    display: flex;

    &_photo {
      flex: 0 0 20%;
      max-width: 20%;
      width: 20%;
      margin-right: 60px;

      .avatar {
        width: 100%;
        padding-top: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 300px;
        object-fit: cover;
      }

      // img {
      //   display: table;
      //   border-radius: 500px;
      //   width: 100%;
      //   object-fit: contain;
      // }
    }

    &_fields,
    &_password {
      flex: auto;

      form {
        padding-top: 10px;

        .wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(5, 1fr);
          grid-auto-flow: column;
          gap: 0 45px;
          margin-bottom: 40px;

          >label {
            margin-bottom: 10px;

            >span {
              font-size: 16px;
              color: #b7b7b7;
              display: table;
            }

            input {
              border: none;
              border-bottom: 1px #4f4f4f solid;
              font-size: 18px;
              background: transparent;
              padding: 4px 0;
              color: #4f4f4f;
              width: 100%;

              &[type="date"] {
                padding: 4px 0 0 0;
              }

              &[type="file"] {
                border: none;
              }
            }

            &[for="gender"] {
              padding: 0;
              margin: 0;
              padding-top: 3px;

              >span {
                margin-bottom: 5px;
              }

              .radios {
                display: flex;

                .checkbox {
                  margin-right: 25px;
                  cursor: pointer;
                }

                .checkbox>input {
                  position: absolute;
                  z-index: -1;
                  opacity: 0;
                }

                .checkbox>span {
                  display: inline-flex;
                  align-items: center;
                  user-select: none;
                }

                .checkbox>span::before {
                  content: "";
                  display: table;
                  width: 16px;
                  height: 16px;
                  background: #e5e5e5;
                  border-radius: 20px;
                  margin-right: 5px;
                }

                // .checkbox
                //   > input:not(:disabled):not(:checked)
                //   + span:hover::before {
                //   border-color: #392977;
                // }

                .checkbox>input:not(:disabled):active+span::before {
                  background-color: #392977;
                }

                // .checkbox > input:focus + span::before {
                //   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
                // }

                .checkbox>input:focus:not(:checked)+span::before {
                  border-color: #392977;
                }

                .checkbox>input:checked+span::before {
                  background-color: #392977;
                  width: 16px;
                  height: 16px;
                  display: table;
                  content: "";
                }

                .checkbox>input:disabled+span::before {
                  background-color: #e9ecef;
                }
              }
            }
          }
        }

        input[type="submit"] {
          cursor: pointer;
          padding: 0 30px;
        }
      }
    }

    &_password {
      margin-left: 30px;

      form {
        .wrapper {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }

    // .widget_recent {
    //   flex: 0 0 30%;
    //   width: 30%;
    //   max-width: 420px;
    //   margin-left: 55px;
    // }
  }

  @media screen and (max-width: 1370px) {
    &_profile {
      &_photo {
        flex: 0 0 20%;
        width: 20%;
        max-width: 20%;
        margin-right: 30px;
      }

      &_password {
        flex: 0 0 25%;
        width: 25%;
        max-width: 25%;
      }

      // .widget_recent {
      //   margin-left: 25px;
      // }
    }
  }

  @media screen and (max-width: 1200px) {
    &_profile {
      &_photo {
        flex: 0 0 13%;
        width: 13%;
        max-width: 13%;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &_profile {
      flex-wrap: wrap;

      &_photo {
        display: none;
      }

      &_fields,
      &_password {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 640px) {
    &_profile {
      &_fields {
        form {
          .wrapper {
            display: flex;
            flex-wrap: wrap;

            >label {
              flex: 0 0 100%;
              width: 100%;
              max-width: 100%;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
</style>