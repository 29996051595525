<template>
  <div class="widget widget_recent">
    <div class="widget_title">Недавние операции</div>
    <div class="widget_recent_list">
      <ul>
        <li v-for="action in recentActions" :key="action.id">
          <div class="summ">
            <span class="plus" v-if="action.type == 'increase'"
              >+ {{ action.count }}</span
            >
            <span class="minus" v-else>- {{ action.count }}</span>
          </div>
          <div class="description">{{ action.comment }}</div>
        </li>
      </ul>
    </div>
    <div class="widget_recent_more_btn">
      <router-link to="/history" class="button">Смотреть все</router-link>
    </div>
  </div>
</template>

<script>
import UserService from "../../data/UserService";

export default {
  data() {
    return {
      recentActions: null
    }
  },
  props: ['userId', 'recentCount'],
  UserService: null,
  created() {
    this.UserService = new UserService();
  },
  mounted() {
    const that = this;
    this.UserService.getTransactionsByUserID(this.userId).then(data => {
      if (data.status) {
        that.recentActions = data.message.slice(0, that.recentCount);
      } else {
        that.$toast.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: data.message,
          life: 4000
        });
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.widget_recent {
  &_list {
    padding-top: 18px;
    padding-left: 1px;
    margin-bottom: 24px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        margin-bottom: 2px;
        .summ {
          min-width: 100px;
          // width: 25%;
          span {
            position: relative;
            font-size: 24px;
            font-weight: 600;
            display: flex;
            align-items: baseline;
            justify-content: flex-end;
            padding-right: 15px;
            &.plus {
              color: #E0491C;
            }
            &.minus {
              color: #000000;
            }
            &:after {
              // position: absolute;
              content: "";
              width: 12px;
              height: 16px;
              display: table;
              background: url("../../assets/img/s_coin.svg") no-repeat center;
              background-size: contain;
              margin-left: 4px;
            }
          }
        }
        .description {
          font-size: 16px;
          flex: auto;
        }
      }
    }
  }

  @media screen and (max-width: 1370px) {
    &_list {
      ul {
        li {
          .summ {
            span {
              font-size: 22px;
            }
          }
          .description {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>