<template>
  <div class="widget_catalog_listing_item widget_catalog_listing_add_new" @click="showCreateModal">
    <div class="frame">
      <p>Добавить позицию</p>
    </div>
  </div>

  <teleport to="body">
    <dialog class="catalogAddDialog" id="createModal">
      <div class="closeModal" @click="closeCreateModal"></div>
      <h3>Добавление новой позиции</h3>
      <form action="" @submit.prevent="createNewProduct" enctype="multipart/form-data">
        <label for="name">
          <span>Название позиции</span>
          <input type="text" name="name" id="name" placeholder="Укажите название позиции" required />
        </label>
        <label for="photo">
          <span>Прикрепите фото</span>
          <input type="file" name="photo" id="photo" />
        </label>
        <label for="description">
          <span>Описание</span>
          <textarea name="description" id="description" rows="3"
            placeholder="Рекомендуется дать развёрнутое описание"></textarea>
        </label>
        <label for="conditions">
          <span>Условия приобретения</span>
          <textarea name="conditions" id="conditions" rows="3"
            placeholder="Обязательно опишите подробные условия использования" required></textarea>
        </label>
        <label for="price">
          <span>Стоимость</span>
          <input type="number" required min="1" max="1000" name="price" id="price" />
        </label>
        <label for="expiration">
          <span>Срок действия (в днях)</span>
          <input type="number" min="0" value="0" required placeholder="Если не ограничено, то указать 0"
            name="expiration" id="expiration" />
        </label>
        <label for="type">
          <span>Тип позиции</span>
          <select required name="type" id="type">
            <option value="default">Обычная привилегия</option>
            <option value="common">Командная покупка</option>
          </select>
        </label>
        <input type="submit" name="send" class="button" value="Добавить в каталог" />
      </form>
    </dialog>
  </teleport>
</template>

<script>
export default {
  props: ['userData'],
  emits: ['updateProducts'],
  methods: {
    showCreateModal() {
      const createModal = document.getElementById("createModal");
      createModal.showModal();
    },
    closeCreateModal() {
      const createModal = document.getElementById("createModal");
      createModal.close();
    },
    createNewProduct(e) {
      const that = this;
      const formData = new FormData(e.target);
      formData.append("action", "createNewProduct");
      formData.append("token", localStorage.starta_token);
      formData.append("c_user_id", that.userData.id);

      fetch(this.$store.state.rootPath + "/actions/catalog.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then(function (data) {
          // console.log(data);
          if (data.status) {
            const createModal = document.getElementById("createModal");
            //that.$store.commit("updateCatalogProducts");
            that.$emit('updateProducts');
            createModal.close();
            e.target.reset();
          }
        })
        .catch(function (err) {
          console.log("Error: ", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.catalogAddDialog {
  position: fixed;
  z-index: 1000;
  width: auto;
  max-width: 640px;
  padding: 30px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  &::backdrop {
    background: rgba(0, 0, 0, 0.7);
  }

  .closeModal {
    width: 22px;
    height: 22px;
    display: table;
    background: url("../../assets/img/close-svgrepo-com.svg") no-repeat center;
    background-size: contain;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  h3 {
    margin: 0 0 20px 0;
    font-size: 26px;
  }

  form {
    >label {
      margin-bottom: 10px;

      >span {
        font-size: 16px;
        color: #b7b7b7;
        display: table;
        margin-bottom: 4px;
      }

      input,
      textarea,
      select {
        border: 1px #afafaf solid;
        font-family: "Raleway", Arial, sans-serif;
        font-feature-settings: "pnum" on, "lnum" on;
        border-radius: 4px;
        font-size: 16px;
        background: transparent;
        padding: 7px 10px;
        color: #4f4f4f;
        width: 100%;
        margin-bottom: 15px;

        &[type="file"] {
          border: none;
          padding: 5px 0;
        }
      }
    }

    input[type="submit"] {
      cursor: pointer;
    }
  }
}

.widget_catalog_listing_add_new {
  .frame {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border: 1px #8fa7ff solid;
    background: #EAEEFF;
    border-radius: 4px;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.1s;

    p {
      font-size: 18px;
      padding: 10px 20px;
    }

    &:hover {
      opacity: 1;
    }
  }
}
</style>