<template>
    <div class="widget widget_balance">
        <div class="widget_title">Баланс</div>
        <div class="widget_balance_summ" :class="balanceClass" v-html="formatBalance"></div>
        <div class="widget_balance_comment">
            <p>{{balanceComment}}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['balance'],
    computed: {
        formatBalance() {
            return "<span>" + this.balance + "</span>";
        },
        balanceComment() {
            let comment = null;
            if (this.balance <= -4) {
                comment = "Эй! Завязывай косячить! 😡";
            } else if (this.balance <= -2) {
                comment = "До боли обидно.. 😓";
            } else if (this.balance < -1) {
                comment = "Не, ну это не серьёзно 🙁";
            } else if (this.balance < 0) {
                comment = "Как же так?! 😱";
            } else if (this.balance == 0) {
                comment = "Самое время начать! 😉";
            } else if (this.balance <= 3) {
                comment = "Стоит поднажать 😌";
            } else if (this.balance <= 5) {
                comment = "Неплохо-неплохо.. 😎";
            } else if (this.balance <= 8) {
                comment = "Отличный результат! 👍";
            } else if (this.balance <= 10) {
                comment = "Ай, молодца! продолжай в том же духе! 🤘";
            } else if (this.balance > 10) {
                comment = "Пора бы уже потратить на что-нибудь. Как считаешь? 🙃";
            } else {
                comment = "Нет слов!.."
            }
            return comment;
        },
        balanceClass() {
            if (this.balance >= 0) {
                return "positive";
            } else {
                return "negative";
            }
            
        }
    }
}
</script>

<style lang="scss" >
    .widget_balance {
        
        &_summ {
            margin-bottom: 8px;
            span {
                font-size: 64px;
                font-weight: 600;
                color: gray;
                position: relative;
                line-height: 1.3;
                &:after {
                    position: absolute;
                    content: '';
                    width: 28px;
                    height: 34px;
                    display: table;
                    background: url('../../assets/img/s_coin.svg') no-repeat center;
                    background-size: contain;
                    bottom: 15px;
                    right: -40px;
                }
            }
            &.positive span {color: #E0491C;}
            &.negative span {color: #000000;}
                        
        }
        &_comment {
            margin-bottom: 15px;
            p {
                font-size: 18px;
            }
        }
    }
</style>