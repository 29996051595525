<template>
  <div class="content_block">
    <h1 class="content_block_title">404 &ndash; Страница не найдена</h1>
    <div class="not-found">
        <p>Такой страницы не существует, сорян (<br>Если хотите, можете обратиться к администратору, но лучше не надо</p>
        <router-link to="/" class="button">Это не справедливо!</router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.not-found {
    p {
        line-height: 1.4;
    }
  .button {
    margin: 20px 0;
    // width: 250px;
  }
}
</style>