<template>
  <div class="content" v-if="userData">
    <the-header v-if="userData" :userData="userData"></the-header>
    <div class="content_block">
      <Toast />
      <h1 class="content_block_title">История операций</h1>
      <div class="widget widget_transactions">
        <div class="widget_title">Все операции</div>
        <div class="widget_transactions_list">
          <div
            class="widget_transactions_list_item"
            v-for="action in userTransactions"
            :key="action.id"
          >
            <div class="date">{{ action.date }}</div>
            <div class="summ">
              <span class="plus" v-if="action.type == 'increase'"
                >+ {{ action.count }}</span
              >
              <span class="minus" v-else>- {{ action.count }}</span>
            </div>
            <div class="description">{{ action.comment }}</div>
          </div>
        </div>
      </div>
      <!-- <widget-dynamic :userData="userData"></widget-dynamic> -->
    </div>
  </div>
</template>

<script>
// import WidgetDynamic from "../layout/ui/WidgetDynamic.vue";
import UserService from "../data/UserService";

export default {
  data() {
    return {
      userTransactions: null,
      userData: null
    }
  },
  components: {
    // "widget-dynamic": WidgetDynamic,
  },
  UserService: null,
  // computed: {
  //   allTransactions() {
  //     // все операции (возможно их нужно как-то подготовить перед выводом)
  //     return this.$store.state.transactions.slice().reverse();
  //   },
  // },
  methods: {
    getUserTransactions() {
        const that = this;
        this.UserService.getTransactionsByUserID(localStorage.starta_id).then(data => {
            if (data.status) {
                this.userTransactions = data.message;
            } else {
                that.$toast.add({
                    severity: 'error',
                    summary: 'Ошибка',
                    detail: data.message,
                    life: 4000
                });
            }
            
        });
    },
    checkAuthorization() {
      const that = this;
      this.UserService.checkAuth().then(data => {
        if (data.status) {
          that.userData = data.message;
        } else {
          that.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
          that.$router.push('/auth');
        }
      })
    }
  },
  created() {
    // await this.$store.dispatch('checkAuth');
    this.UserService = new UserService();

  },
  mounted() {
    this.checkAuthorization();
    this.getUserTransactions();
  }
};
</script>

<style lang="scss" scoped>
.widget_transactions {
  &_list {
    padding-top: 18px;
    padding-left: 1px;
    margin-bottom: 24px;
    &_item {
      display: flex;
      flex-wrap: nowrap;
      align-items: baseline;
      justify-content: space-between;
      padding: 7px 10px 14px 10px;
      margin: 0;
      border-bottom: 1px #dfdfcd solid;
      .date {
        flex: 0 0 15%;
        width: auto;
        min-width: 150px;
        white-space: nowrap;
        padding-right: 25px;
        font-size: 18px;
      }
      .summ {
        flex: 0 0 10%;
        width: 10%;
        min-width: 100px;
        white-space: nowrap;
        padding-right: 25px;
        span {
          position: relative;
          font-size: 24px;
          font-weight: 600;
          display: flex;
          align-items: baseline;
            &.plus {
              color: #E0491C;
            }
            &.minus {
              color: #000000;
            }
          &:after {
            // position: absolute;
            content: "";
            width: 12px;
            height: 16px;
            display: table;
            background: url("../assets/img/s_coin.svg") no-repeat center;
            background-size: contain;
            margin-left: 4px;
          }
        }
      }
      .description {
        font-size: 16px;
        flex: auto;
        // width: 100%;
      }
      &:last-of-type {
        border: none;
      }
      &:hover {
        background: #fafaea;
      }
    }
  }

  @media screen and (max-width: 640px) {
    &_list {
      &_item {
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 7px 0 14px 0;
        .description {
          flex: 0 0 100%;
          width: 100%;
          max-width: 100%;
          margin-top: 8px;
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    &_list {
      &_item {
        justify-content: space-between;
        .date {
          order: 2;
          flex: 0 0 50%;
          width: 50%;
          max-width: 50%;
          min-width: auto;
          margin: 0;
          padding: 0;
          text-align: right;
          font-size: 14px;
          color: gray;
        }
        .summ {
          order: 1;
          flex: 0 0 50%;
          width: 50%;
          min-width: auto;
          max-width: 50%;
          margin: 0;
          padding: 0;
        }
        .description {
          order: 3;
          font-size: 14px;
        }
      }
    }
  }
}
</style>