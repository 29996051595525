<template>
<div class="content" v-if="userData">
    <the-header v-if="userData" :userData="userData"></the-header>
    <Toast />
    <div class="content_block content_block_admin_users" v-if="showAdminControls">
      <div class="admin_topbar flex p-5">
        <router-link to="/admin/users" class="mr-2">
          <Button label="Пользователи" icon="pi pi-user" class="p-button" />
        </router-link>
        <router-link to="/admin/transactions" class="mr-2">
          <Button label="Операции" icon="pi pi-sort-alt" class="p-button" />
        </router-link>
      </div>
      
        <div>
        
        <div class="widget">
            <h1 class="widget_title">Все махинации</h1>
            <DataTable :value="transactions" :paginator="true" class="p-datatable-customers"  responsiveLayout="stack" breakpoint="1024px" :rows="20" dataKey="id" v-model:filters="filters" filterDisplay="menu" :loading="loading" :globalFilterFields="['global','user.name','type','count', 'comment']" sortField="date" :sortOrder="-1">
                <template #header>
                    <div class="flex flex-wrap justify-content-between">
                        <div class="controls flex mb-2">
                            <Button label="Добавить транзакцию" icon="pi pi-plus-circle" class="p-button-sm" @click="openTransactionModal" />
                        </div>
                        <div class="search flex flex-wrap mb-2">
                            <Button type="button" icon="pi pi-filter-slash" label="Сбросить фильтр" class="p-button p-component p-button-outlined p-button-sm mr-2 mb-2" @click="clearFilter()"/>
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Поиск" class="p-inputtext-sm"/>
                            </span>
                        </div>
                    </div>
                </template>
                <template #empty>
                    Ничего не найдено
                </template>
                <template #loading>
                    Загрузка..
                </template>
                <Column field="date" filterField="date" dataType="date" header="Дата" :sortable="true" class="w-2">
                    <template #body="{data}">
                        {{formatDate(data.date)}}
                    </template>
                    <template #filter="{filterModel}">
                        <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
                    </template>
                </Column>
                <Column header="Пользователь" :showFilterMatchModes="false" field="user.name" filterField="user.name" :sortable="true" class="w-4 user_col">
                    <template #body="{data}">
                        <router-link :to="'/profile/' + data.user.id">
                            <Avatar :image="this.$store.state.rootPath + '/users_data/' + data.user.photo" class="mr-2" shape="circle" style="vertical-align: middle;" v-if="data.user.photo" />
                            <Avatar :image="this.$store.state.rootPath + '/users_data/no_photo.jpg'" class="mr-2" shape="circle" style="vertical-align: middle;" v-else/>
                            <span class="image-text" style="margin-left: 10px">{{data.user.name}}</span>
                        </router-link>
                    </template>
                    <template #filter="{filterModel}">
                        <div class="mb-3 font-bold">Выберите пользователя</div>
                        <Dropdown v-model="filterModel.value" :options="usersForFilter" placeholder="Любой" class="p-column-filter" :showClear="true">
                            <template #value="slotProps">
                                <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                <span v-else>{{slotProps.placeholder}}</span>
                            </template>
                            <template #option="slotProps">
                                <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column field="type" header="Операция" :sortable="true" class="w-2">
                    <template #body="{data}">
                        <span :class="'customer-badge status-' + data.type">{{formatType(data.type)}}</span>
                    </template>
                    <template #filter="{filterModel}">
                        <Dropdown v-model="filterModel.value" :options="types" placeholder="Любой" class="p-column-filter" :showClear="true">
                            <template #value="slotProps">
                                <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{formatType(slotProps.value)}}</span>
                                <span v-else>{{slotProps.placeholder}}</span>
                            </template>
                            <template #option="slotProps">
                                <span :class="'customer-badge status-' + slotProps.option">{{formatType(slotProps.option)}}</span>
                            </template>
                        </Dropdown>
                    </template>
                </Column>
                <Column field="count" header="Количество" :sortable="true" class="w-1">
                    <template #body="{data}">
                        <span v-html="formatBalance(data.count)" class="s_count"></span>
                    </template>
                    <!-- <template #filter="{filterModel}">
                        <InputNumber v-model="filterModel.value" />
                    </template> -->
                </Column>
                <Column field="comment" header="Комментарий" class="w-3 comment">
                    <template #body="{data}">
                        <p style="word-break: break-word;">{{data.comment}}</p>
                    </template>
                </Column>
            </DataTable>
        </div>

        <Dialog header="Новая транзакция" v-model:visible="displayTransactionModal" class="transaction_modal">
            <form class="flex flex-wrap" ref="transactionForm">
                <div class="field w-full">
                    <label for="user">Пользователь</label>
                    <div class="user_selector">
                        <Dropdown v-model="selectedUser" :options="users" optionLabel="name" name="receiver" :filter="true" placeholder="Выберите пользователя" :showClear="true" required>
                            <template #value="slotProps">
                                <div class="user-item user-item-value" v-if="slotProps.value">
                                    <Avatar :image="this.$store.state.rootPath + `/users_data/${slotProps.value.image ? slotProps.value.image : 'no_photo.jpg'}`" class="mr-2" shape="circle" style="vertical-align: middle;"/>
                                    <span class="image-text" style="margin-left: 10px">{{slotProps.value.name}}</span>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div class="user-item">
                                    <Avatar :image="this.$store.state.rootPath + '/users_data/' + slotProps.option.image" class="mr-2" shape="circle" style="vertical-align: middle;" v-if="slotProps.option.image" />
                                    <Avatar :image="this.$store.state.rootPath + '/users_data/no_photo.jpg'" class="mr-2" shape="circle" style="vertical-align: middle;" v-else/>
                                    <span class="image-text" style="margin-left: 10px">{{slotProps.option.name}}</span>
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                </div>
                <div class="field flex-auto pr-3">
                    <label for="type">Действие</label>
                    <SelectButton v-model="selectedType" @change="changeBtnText" :options="types" id="type" required>
                        <template #option="slotProps">
                            {{formatType(slotProps.option)}}
                        </template>
                    </SelectButton>
                </div>
                <div class="field flex-auto">
                    <label for="count">Количество Стартиков</label>
                    <div class="counter">
                        <InputNumber id="count" name="count" :min="1" :max="100" v-model="selectedCount" showButtons buttonLayout="horizontal" :step="1" decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" required/>
                    </div>
                </div>
                <div class="field w-full">
                    <label for="comment">Комментарий</label>
                    <Textarea v-model="selectedComment" name="comment" :autoResize="true" rows="4" style="width: 100%" placeholder="Коротко о причине изменения баланса"/>
                </div>
            </form>
            <template #footer>
                <Button label="Отмена" icon="pi pi-times" @click="closeTransactionModal" class="p-button-raised p-button-danger p-button-text"/>
                <Button :label="transactionBtnText" icon="pi pi-check" @click="setTransaction" class="p-button-raised" autofocus />
            </template>
        </Dialog>
        </div>

    </div>

    <div class="content_block" v-else>
        <h1 class="content_block_title">401 &ndash; Доступ запрещён</h1>
        <div class="access-denied">
            <p>Для просмотра данной страницы необходимы расширенные права.<br />Если хотите, можете обратиться к администратору, но лучше не надо
            </p>
            <router-link to="/" class="button">Это не справедливо!</router-link>
        </div>
    </div>
</div>
</template>

<script>
import AdminService from './data/AdminService';
import UserService from "../../data/UserService";
// import {FilterMatchMode,FilterOperator} from 'primevue/api';
import {FilterMatchMode} from 'primevue/api';

export default {
    data() {
        return {
            displayTransactionModal: false,
            transactions: null,
            filters: null,
            loading: true,
            selectedUser: null,
            selectedType: 'increase',
            selectedCount: 1,
            selectedComment: null,
            transactionBtnText: 'Начислить',
            types: ['increase','decrease'],
            users: null,
            userData: null,
            showAdminControls: false,
        }
    },
    AdminService: null,
    UserService: null,
    created() {
        this.AdminService = new AdminService();
        this.UserService = new UserService();
        this.initFilters();
    },
    mounted() {
        this.checkAuthorization();
        this.checkPermissions();
        this.getAllUsers();
        this.getAllTransactions();
        
    },
    computed: {
        usersForFilter() {
            var output = [];
            this.users.forEach(function(item) {
                output.push(item.name);
            });
            return output;
        }
    },
    methods: {
        checkAuthorization() {
            const that = this;
            this.UserService.checkAuth().then(data => {
                if (data.status) {
                    that.userData = data.message;
                } else {
                that.$toast.add({
                    severity: 'error',
                    summary: 'Ошибка',
                    detail: data.message,
                    life: 4000
                });
                that.$router.push('/auth');
                }
            })
        },
        checkPermissions() {
            const that = this;
            this.UserService.getUserPermissions().then(data => {
                if (data.status) {
                    if (data.message === 'admin') {
                        that.showAdminControls = true;
                    } else {
                        that.showAdminControls = false;
                    }
                } else {
                that.$toast.add({
                    severity: 'error',
                    summary: 'Ошибка',
                    detail: data.message,
                    life: 4000
                });
                that.showAdminControls = false;
                }
            })
        },
        getAllUsers() {
            const that = this;
            this.AdminService.getUsers().then(data => {
                if (data.status) {
                    this.users = data.message;
                } else {
                    that.$toast.add({
                        severity: 'error',
                        summary: 'Ошибка',
                        detail: data.message,
                        life: 4000
                    });
                }
                
            });
        },
        getAllTransactions() {
            const that = this;
            this.AdminService.getTransactions().then(data => {
                if (data.status) {
                    // console.log(data.message);
                    this.transactions = data.message;
                    this.loading = false;
                    this.transactions.forEach(transaction_item => transaction_item.date = new Date(transaction_item.date));
                } else {
                    that.$toast.add({
                        severity: 'error',
                        summary: 'Ошибка',
                        detail: data.message,
                        life: 4000
                    });
                }
                
            });
        },
        openTransactionModal() {
            this.displayTransactionModal = true;
        },
        closeTransactionModal() {
            this.$refs.transactionForm.reset();
            this.displayTransactionModal = false;
        },
        // начисление/списание с баланса пользователя
        setTransaction() {

            if (this.selectedUser && this.selectedCount) {
                const that = this;
                const formData = new FormData(this.$refs.transactionForm);
                formData.append("action", "setTransactionByUserId");
                formData.append("token", localStorage.starta_token);
                formData.append("c_user_id", localStorage.starta_id);
                formData.append("type", this.selectedType);
                formData.append("receiver", this.selectedUser.id);

                this.AdminService.setTransactions(formData).then(data => {
                    if (data.status) {
                        this.getAllTransactions();
                        that.closeTransactionModal();
                        that.$toast.add({
                            severity: 'success',
                            detail: data.message,
                            life: 4000
                        });
                    } else {
                        that.$toast.add({
                            severity: 'error',
                            summary: 'Ошибка',
                            detail: data.message,
                            life: 4000
                        });
                    }
                });
            } else {
                this.$toast.add({
                    severity: 'warn',
                    detail: 'Обязательные поля не заполнены',
                    life: 4000
                });
            }

        },
        changeBtnText() {
            if (this.selectedType == 'decrease') {
                this.transactionBtnText = 'Списать';
            } else {
                this.transactionBtnText = 'Начислить';
            }
        },
        formatType(value) {
            switch (value) {
                case 'increase':
                    return 'Начисление';
                case 'decrease':
                    return 'Списание';
                default: 
                    return '';
            }
        },
        formatBalance(value) {
            return value + '<span class="s_coin"></span>';
        },
        formatDate(value) {
            return value.toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            });
        },
        clearFilter() {
            this.initFilters();
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                // 'receiver_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                // 'user.name': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'user.name': {value: null, matchMode: FilterMatchMode.EQUALS},
                'date': {value: null, matchMode: FilterMatchMode.DATE_IS},
                'count': {value: null, matchMode: FilterMatchMode.EQUALS},
                'type': {value: null, matchMode: FilterMatchMode.EQUALS}
            }
        }
    }
}
</script>

<style lang="scss">

    .access-denied {
        p {
            line-height: 1.4;
        }
        .button {
            margin: 20px 0;
            // width: 250px;
        }
    }
    
    form {
        .field > * {
            display: block;
        }
        .p-inputnumber-input {
            max-width: 60px;
            text-align: center;
        }
    }

    .s_count {
        align-items: center;
        display: flex;
        .s_coin {
            width: 13px;
            height: 13px;
            background: url('../../assets/img/s_coin.svg') no-repeat center;
            background-size: contain;
            display: inline-table;
        }
    }

    td.comment p {
        @media screen and (max-width: 1024px) {
            text-align: right;
            margin: 0 0 0 auto;
            padding-left: 15px;
        }
    }
    td.user_col .p-avatar {
        @media screen and (max-width: 480px) {
            display: none;
        }
    }
    .transaction_modal {
        width: 95%;
        max-width: 600px;
        @media screen and (max-width: 600px) {
            .user_selector {
                .p-dropdown {
                    width: 100%;
                }
            }   
        }
    }

    ::v-deep(.p-paginator) {
        .p-paginator-current {
            margin-left: auto;
        }
    }

    ::v-deep(.p-progressbar) {
        height: .5rem;
        background-color: #D8DADC;

        .p-progressbar-value {
            background-color: #607D8B;
        }
    }

    ::v-deep(.p-datepicker) {
        min-width: 25rem;

        td {
            font-weight: 400;
        }
    }

    ::v-deep(.p-datatable.p-datatable-customers) {
        .p-datatable-header {
            padding: 1rem;
            text-align: left;
            font-size: 1.5rem;
        }

        .p-paginator {
            padding: 1rem;
        }

        .p-datatable-thead > tr > th {
            text-align: left;
        }

        .p-datatable-tbody > tr > td {
            cursor: auto;
        }

        .p-dropdown-label:not(.p-placeholder) {
            text-transform: uppercase;
        }
    }
</style>