export default class AdminService {

    getUsers() {
		const formData = new FormData();
		formData.append('action', 'getAllUsers');
		formData.append('token', localStorage.starta_token);
		formData.append('c_user_id', localStorage.starta_id);

		return fetch(process.env.VUE_APP_URL + "/actions/users.php", {
			method: "POST",
			body: formData
		})
			.then((response) => response.json())
			.then(function (data) {
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			})
	}

	getTransactions() {
		const formData = new FormData();
		formData.append('action', 'getAllTransactions');
		formData.append('token', localStorage.starta_token);
		formData.append('c_user_id', localStorage.starta_id);

		return fetch(process.env.VUE_APP_URL + "/actions/transactions.php", {
			method: "POST",
			body: formData
		})
			.then((response) => response.json())
			.then(function (data) {
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			})
    }

	setTransactions(formData) {
		return fetch(process.env.VUE_APP_URL + "/actions/transactions.php", {
			method: "POST",
			body: formData,
		})
			.then((response) => response.json())
			.then(function (data) {
				if (data.status) {
					// store.commit("updateUserBalance", data.message);
					// store.commit("updateUsersList");
					return Promise.resolve(data);
				} else {
					return Promise.resolve(data);
				}
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			});
	}

	createUser(formData) {
		return fetch(process.env.VUE_APP_URL + "/actions/users.php", {
			method: "POST",
			body: formData,
		})
			.then((response) => response.json())
			.then(function (data) {
				if (data.status) {
					return Promise.resolve(data);
				} else {
					return Promise.resolve(data);
				}
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			});
	}

	deleteUser(formData) {
		return fetch(process.env.VUE_APP_URL + "/actions/users.php", {
			method: "POST",
			body: formData,
		})
			.then((response) => response.json())
			.then(function (data) {
				if (data.status) {
					return Promise.resolve(data);
				} else {
					return Promise.resolve(data);
				}
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			});
	}
}