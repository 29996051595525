<template>
  <div class="content" v-if="userData">
    <the-header v-if="userData" :userData="userData"></the-header>
    <Toast />
    <div class="content_block">
      <div class="widget widget_product" v-if="productData.length != 0">
        <div class="widget_product_image">
          <img v-if="productData.photo" :src="this.$store.state.rootPath + '/catalog_images/' + productData.photo"
            :alt="productData.name" />
          <img v-else :src="this.$store.state.rootPath + '/catalog_images/no_photo.jpg'" :alt="productData.name" />
        </div>
        <div class="widget_product_content">
          <h1>{{ productData.name }}</h1>
          <div class="description" v-if="productData.description">
            {{ productData.description }}
          </div>
          <div class="conditions" v-if="productData.conditions">
            <h3>Условия предоставления</h3>
            {{ productData.conditions }}
          </div>
          <div class="conditions" v-if="productData.expiration != 0">
            <h3>Срок действия: <span class="red">{{ productData.expiration }} {{ daysWord }} с момента покупки</span>
            </h3>

          </div>
          <div class="controls">
            <!-- <div class="price">
              <span>{{ productData.price }}</span>
            </div> -->
            <div class="price_with_discount" v-if="productData.sale_price != 0">
              <div class="price">
                <span class="s_coin sale_price" title="Скидка!!">{{ productData.sale_price }}</span>
              </div>
              <span class="s_coin oldprice">{{ productData.price }}</span>
            </div>
            <div v-else>
              <div class="price">
                <span class="s_coin">{{ productData.price }}</span>
              </div>
            </div>
            <a nohref class="button" @click="buyProduct(productData.id)">Купить</a>
          </div>
        </div>
      </div>
      <div class="widget widget_preloader" v-else>
        <div class="preloader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '../data/UserService';
import ProductService from '../data/ProductService';

export default {
  data() {
    return {
      id: this.$route.params.id,
      productData: [],
      userData: null
    };
  },
  UserService: null,
  ProductService: null,
  mounted() {
    this.checkAuthorization();
    this.getProductData(this.$route.params.id);
  },
  computed: {
    daysWord() {
      let words = ['день', 'дня', 'дней'];
      let num = this.productData.expiration;
      return words[(num % 100 > 4 && num % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(num % 10 < 5) ? Math.abs(num) % 10 : 5]]
    }
  },
  methods: {
    checkAuthorization() {
      const that = this;
      this.UserService.checkAuth().then(data => {
        if (data.status) {
          that.userData = data.message;
        } else {
          that.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
          that.$router.push('/auth');
        }
      })
    },
    getProductData(id) {
      const that = this;

      this.ProductService.getProductData(id).then(data => {
        if (data.status) {
          that.productData = data.message;
        } else {
          that.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
        }
      });
    },
    buyProduct(id) {
      this.ProductService.buyProduct(id).then(data => {
        if (data.status) {
          this.$toast.add({
            severity: 'success',
            // summary: 'Баланс изменён',
            detail: data.message,
            life: 4000
          });
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
        }
      });

    },
    buyCommonProduct(id) {
      this.ProductService.buyCommonProduct(id).then(data => {
        if (data.status) {
          this.$toast.add({
            severity: 'success',
            detail: data.message,
            life: 4000
          });
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
        }
      });

    },
  },
  created() {
    this.UserService = new UserService();
    this.ProductService = new ProductService();
  },
};
</script>

<style lang="scss" scoped>
.widget {
  &_product {
    padding-top: 35px;
    display: flex;

    &_image {
      flex: 0 0 40%;
      width: 40%;
      max-width: 435px;

      img {
        display: block;
        width: 100%;
        object-fit: contain;
      }
    }

    &_content {
      flex: 0 0 60%;
      width: 60%;
      max-width: 60%;
      padding-left: 40px;

      h1 {
        margin-bottom: 30px;
        font-weight: 500;
      }

      h3 {
        font-size: 22px;
        font-weight: 500;
      }

      .description,
      .conditions {
        line-height: 1.3;
        margin-bottom: 25px;

        span.red {
          color: rgb(206, 0, 0);
        }
      }

      .controls {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-start;
        gap: 20px;
        .price {
          display: flex;
          width: 60px;
          height: 60px;
          align-items: center;
          justify-content: center;
          background: #392977;
          border-radius: 100px;
          margin-right: 28px;

          span {
            font-size: 22px;
            font-weight: 500;
            color: white;
            display: flex;
            flex-wrap: nowrap;
            align-items: baseline;

            &:after {
              content: "";
              width: 12px;
              height: 17px;
              background: url("../assets/img/s_coin.svg") no-repeat center;
              background-size: contain;
              display: table;
              filter: brightness(10);
              margin-left: 1px;
            }
          }

          &_with_discount {
            display: flex;
            align-items: center;

            .price {
              background-color: #E0491C;
            }

            .oldprice {
              position: relative;
              display: flex;
              align-items: center;
              font-size: 22px;
              color: #747474;
              &:after {
                content: "";
                width: 12px;
                height: 17px;
                background: url("../assets/img/s_coin.svg") no-repeat center;
                background-size: contain;
                display: table;
                margin-left: 1px;
              }
              &:before {
                width: calc(100% + 8px);
                height: 2px;
                display: table;
                left: -4px;
                right: -4px;
                content: '';
                background-color: #747474;
                top: 56%;
                transform: translateY(-50%) rotate(12deg);
                position: absolute;
              }
            }
          }
        }

        .button {
          padding: 0 33px;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &_product {
      &_image {
        flex: 0 0 30%;
        width: 30%;
        max-width: 30%;
      }

      &_content {
        flex: 0 0 70%;
        width: 70%;
        max-width: 70%;
        padding-left: 30px;

        h1 {
          font-size: 26px;
          margin-bottom: 15px;
        }

        h3 {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    &_product {
      flex-wrap: wrap;

      &_image {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
        margin-bottom: 35px;
      }

      &_content {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
        padding-left: 0;
      }
    }
  }
}
</style>