export default class UserService {
    checkAuth() {
        if (localStorage.starta_token && localStorage.starta_id) {
            const formData = new FormData();
            formData.append('action', 'getUserByToken');
            formData.append('token', localStorage.starta_token);
            formData.append('c_user_id', localStorage.starta_id);
    
            return fetch(process.env.VUE_APP_URL + "/actions/users.php", {
                method: "POST",
                body: formData
            })
                .then((response) => response.json())
                .then(function (data) {
                    // console.log(data);
                    return Promise.resolve(data);
                })
                .catch(function (err) {
                    var data = {status: false, message: err}
                    console.log(data);
                    return Promise.resolve(data);
                })
          } else {
            var data = {status: false, message: 'Ошибка авторизации'}
            return Promise.resolve(data);
          }
    }

    getUserPermissions() {
        if (localStorage.starta_token && localStorage.starta_id) {
            const formData = new FormData();
            formData.append('action', 'getUserPermissions');
            formData.append('token', localStorage.starta_token);
            formData.append('c_user_id', localStorage.starta_id);
    
            return fetch(process.env.VUE_APP_URL + "/actions/users.php", {
                method: "POST",
                body: formData
            })
                .then((response) => response.json())
                .then(function (data) {
                    return Promise.resolve(data);
                })
                .catch(function (err) {
                    var data = {status: false, message: err}
                    return Promise.resolve(data);
                })
          } else {
            var data = {status: false, message: 'Не удалось получить права пользователя'}
            return Promise.resolve(data);
          }
    }

	getTransactionsByUserID(id) {
		const formData = new FormData();
		formData.append('action', 'getTransactionsByUserId');
		formData.append('token', localStorage.starta_token);
		formData.append('c_user_id', localStorage.starta_id);
        formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/actions/transactions.php", {
			method: "POST",
			body: formData
		})
			.then((response) => response.json())
			.then(function (data) {
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {status: false, message: err}
                // console.log(data);
				return Promise.resolve(data);
			})
    }
}