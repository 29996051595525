export default class ProductService {
    getProducts() {
		const formData = new FormData();
		formData.append('action', 'getAllProducts');
		formData.append('token', localStorage.starta_token);
		formData.append('c_user_id', localStorage.starta_id);

		return fetch(process.env.VUE_APP_URL + "/actions/catalog.php", {
			method: "POST",
			body: formData
		})
			.then((response) => response.json())
			.then(function (data) {
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			})
    }

    getCommonProducts() {
		const formData = new FormData();
		formData.append('action', 'getAllCommonProducts');
		formData.append('token', localStorage.starta_token);
		formData.append('c_user_id', localStorage.starta_id);

		return fetch(process.env.VUE_APP_URL + "/actions/catalog.php", {
			method: "POST",
			body: formData
		})
			.then((response) => response.json())
			.then(function (data) {
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			})
    }

    getProductData(id) {
		const formData = new FormData();
		formData.append('action', 'getProductByID');
		formData.append('token', localStorage.starta_token);
		formData.append('c_user_id', localStorage.starta_id);
        formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/actions/catalog.php", {
			method: "POST",
			body: formData
		})
			.then((response) => response.json())
			.then(function (data) {
				console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			})
    }

    buyProduct(id) {
		const formData = new FormData();
		formData.append('action', 'createOrder');
		formData.append('token', localStorage.starta_token);
		formData.append('c_user_id', localStorage.starta_id);
        formData.append('product_id', id);

		return fetch(process.env.VUE_APP_URL + "/actions/orders.php", {
			method: "POST",
			body: formData
		})
			.then((response) => response.json())
			.then(function (data) {
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			})
    }

    buyCommonProduct(id) {
		const formData = new FormData();
		formData.append('action', 'createCommonOrder');
		formData.append('token', localStorage.starta_token);
		formData.append('c_user_id', localStorage.starta_id);
        formData.append('product_id', id);

		return fetch(process.env.VUE_APP_URL + "/actions/orders.php", {
			method: "POST",
			body: formData
		})
			.then((response) => response.json())
			.then(function (data) {
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {status: false, message: err}
				return Promise.resolve(data);
			})
    }
}