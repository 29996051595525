<template>
  <div class="content" v-if="userData">
    <the-header v-if="userData" :userData="userData"></the-header>
    <Toast />
    <div class="content_block">
      <div class="widget widget_product" v-if="orderData.length != 0">
        <div class="widget_product_image">
          <img
            v-if="orderData.photo"
            :src="this.$store.state.rootPath + '/catalog_images/' + orderData.photo"
            :alt="orderData.name"
          />
          <img v-else :src="this.$store.state.rootPath + '/catalog_images/no_photo.jpg'" :alt="orderData.name" />
        </div>
        <div class="widget_product_content">
          <h4>Командная покупка</h4>
          <h1>{{ orderData.name }}</h1>
          <div class="description" v-if="orderData.description">
            {{ orderData.description }}
          </div>
          <div class="conditions" v-if="orderData.conditions">
            <h3>Условия предоставления</h3>
            {{ orderData.conditions }}
          </div>
          <div class="status">
            <h3 v-if="orderData.status == 'in_progress'">На эту покупку ведётся сбор стартиков </h3>
            <h3 v-else-if="orderData.status == 'completed'">Сбор стартиков завершён! </h3>
            <h3 v-else>Сбор ещё никто не начинал. Начни ты!</h3>
          </div>
          <div class="controls">
            <div class="price">
              <span title="Стоимость">{{ orderData.price }}</span>
            </div>
            <div class="flex align-items-center" v-if="orderData.status == 'in_progress'">
              <div class="price price-collected">
                <span title="Накоплено">{{ orderData.collected }}</span>
              </div>
              <a nohref class="button" @click="setDonate(orderData.product_id, 1)">Сделать взнос</a>
            </div>
            <div v-else-if="orderData.status != 'completed'">
              <a nohref class="button" @click="buyCommonProduct(orderData.product_id)">Запустить сбор</a>
            </div>
          </div>
          <div class="donaters" v-if="orderData.status == 'in_progress' || orderData.status == 'completed'">
            <h3>Список тех, кто сделал вклад</h3>
            <div class="widget_transactions_list">
              <div
                class="widget_transactions_list_item" v-for="donate in orderDonates" :key="donate.id">
                <div class="date">{{ donate.date }}</div>
                <div class="summ">
                  <span class="plus">+ {{ donate.contribution }}</span>
                </div>
                <div class="description" v-if="!donate.firstname"><span class="mysterious">Тайный доброжелатель</span></div>
                <div class="description" v-else>{{ donate.firstname }} {{ donate.lastname }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="widget widget_preloader" v-else>
        <div class="preloader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '../data/UserService';
import ProductService from '../data/ProductService';
import CommonService from '../data/CommonService';

export default {
  data() {
    return {
      id: this.$route.params.id,
      orderDonates: [],
      orderData: [],
      userData: null
    };
  },
  UserService: null,
  ProductService: null,
  CommonService: null,
  mounted() {
    this.checkAuthorization();
    this.getCommonOrderByID(this.$route.params.id);
    this.getDonates(this.$route.params.id);
  },
  computed: {
    daysWord() {
      let words = ['день', 'дня', 'дней'];
      let num = this.orderData.expiration;
      return words[(num % 100 > 4 && num % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(num % 10 < 5) ? Math.abs(num) % 10 : 5]]
    }
  },
  methods: {
    checkAuthorization() {
      const that = this;
      this.UserService.checkAuth().then(data => {
        if (data.status) {
          that.userData = data.message;
        } else {
          that.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
          that.$router.push('/auth');
        }
      })
    },
    getCommonOrderByID(id) {
      const that = this;

      this.CommonService.getCommonOrderByID(id).then(data => {
        if (data.status) {
          that.orderData = data.message;
        } else {
          that.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
        }
      });
    },
    getDonates(id) {
      this.CommonService.getDonates(id).then(data => {
          if (data.status) {
              this.orderDonates = data.message;
          } else {
              this.$toast.add({
                  severity: 'error',
                  summary: 'Ошибка',
                  detail: data.message,
                  life: 4000
              });
          }
      });
    },
    checkCommonCost(id) {
      this.CommonService.checkCommonCost(id).then(data => {
          if (data.status) {
            this.getCommonOrderByID(this.$route.params.id);
          } else {
              this.$toast.add({
                  severity: 'error',
                  summary: 'Ошибка',
                  detail: data.message,
                  life: 4000
              });
          }
      });
    },
    buyCommonProduct(id) {
      this.ProductService.buyCommonProduct(id).then(data => {
          if (data.status) {
              this.setDonate(this.orderData.product_id, 1);
              this.getCommonOrderByID(this.$route.params.id);
              this.getDonates(this.$route.params.id);
              this.checkAuthorization();
          } else {
              this.$toast.add({
                  severity: 'error',
                  summary: 'Ошибка',
                  detail: data.message,
                  life: 4000
              });
          }
      });

    },
    setDonate(id, summ) {
      this.CommonService.setCommonDonate(id, summ).then(data => {
          if (data.status) {
              this.getCommonOrderByID(this.$route.params.id);
              this.getDonates(this.$route.params.id);
              this.checkCommonCost(this.$route.params.id);
              this.checkAuthorization();
              this.$toast.add({
                  severity: 'success',
                  detail: data.message,
                  life: 4000
              });
          } else {
              this.$toast.add({
                  severity: 'error',
                  summary: 'Ошибка',
                  detail: data.message,
                  life: 4000
              });
          }
      });

    },
  },
  created() {
    this.UserService = new UserService();
    this.ProductService = new ProductService();
    this.CommonService = new CommonService();
  },
};
</script>

<style lang="scss" scoped>
.widget {
  &_product {
    padding-top: 35px;
    display: flex;
    &_image {
      flex: 0 0 30%;
      width: 30%;
      max-width: 370px;
      img {
        display: block;
        width: 100%;
        object-fit: contain;
      }
    }
    &_content {
      flex: 0 0 70%;
      width: 70%;
      max-width: 70%;
      padding-left: 40px;
      h1 {
        margin-bottom: 30px;
        font-weight: 500;
        margin-bottom: 15px
      }
      h3 {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 15px;
        margin-top: 0;
      }
      h4 {
        margin: 0;
        color: #E0491C;
      }
      .description,
      .conditions {
        line-height: 1.3;
        margin-bottom: 45px;
        span.red {
          color: rgb(206, 0, 0);
        }
      }
      .controls {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin-bottom: 45px;
        .price {
          display: flex;
          width: 60px;
          height: 60px;
          align-items: center;
          justify-content: center;
          background: #392977;
          border-radius: 100px;
          margin-right: 28px;
          position: relative;
          margin-top: 10px;
          span {
            font-size: 22px;
            font-weight: 500;
            color: white;
            display: flex;
            flex-wrap: nowrap;
            align-items: baseline;
            &:after {
              content: "";
              width: 12px;
              height: 17px;
              background: url("../assets/img/s_coin.svg") no-repeat center;
              background-size: contain;
              display: table;
              filter: brightness(10);
              margin-left: 1px;
            }
          }
          &::before {
            content: 'Стоимость';
            position: absolute;
            top: -17px;
            font-size: 12px;
            text-align: center;
            margin: 0 auto;
          }
          &-collected {
            background: #E0491C;
            &::before {
              content: 'Накоплено';
            }
          }
        }
        .button {
          padding: 0 33px;
        }
      }
    }
  }

  &_transactions {
    &_list {
      // padding-top: 18px;
      padding-left: 1px;
      margin-bottom: 24px;
      &_item {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 7px 10px 14px 10px;
        margin: 0;
        border-bottom: 1px #dfdfcd solid;
        .date {
          flex: 0 0 25%;
          width: auto;
          min-width: 150px;
          white-space: nowrap;
          padding-right: 25px;
          font-size: 18px;
        }
        .summ {
          flex: 0 0 10%;
          width: 10%;
          min-width: 100px;
          white-space: nowrap;
          padding-right: 25px;
          span {
            position: relative;
            font-size: 24px;
            font-weight: 600;
            display: flex;
            align-items: baseline;
              &.plus {
                color: #E0491C;
              }
              &.minus {
                color: #000000;
              }
            &:after {
              // position: absolute;
              content: "";
              width: 12px;
              height: 16px;
              display: table;
              background: url("../assets/img/s_coin.svg") no-repeat center;
              background-size: contain;
              margin-left: 4px;
            }
          }
        }
        .description {
          font-size: 16px;
          flex: auto;
          margin: 0;
          .mysterious {
            color: #b7b7b7;
          }
        }
        &:last-of-type {
          border: none;
        }
        &:hover {
          background: #fafaea;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &_product {
      &_image {
        flex: 0 0 30%;
        width: 30%;
        max-width: 30%;
      }
      &_content {
        flex: 0 0 70%;
        width: 70%;
        max-width: 70%;
        padding-left: 30px;
        h1 {
          font-size: 26px;
          margin-bottom: 15px;
        }
        h3 {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    &_product {
      flex-wrap: wrap;
      &_image {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
        margin-bottom: 35px;
      }
      &_content {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
        padding-left: 0;
      }
    }
  }
  @media screen and (max-width: 640px) {
    &_transactions {
      &_list {
        &_item {
          flex-wrap: wrap;
          justify-content: flex-start;
          padding: 7px 0 14px 0;
          .description {
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
            margin-top: 8px;
          }
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    &_transactions {
      &_list {
        &_item {
          justify-content: space-between;
          .date {
            order: 2;
            flex: 0 0 50%;
            width: 50%;
            max-width: 50%;
            min-width: auto;
            margin: 0;
            padding: 0;
            text-align: right;
            font-size: 14px;
            color: gray;
          }
          .summ {
            order: 1;
            flex: 0 0 50%;
            width: 50%;
            min-width: auto;
            max-width: 50%;
            margin: 0;
            padding: 0;
          }
          .description {
            order: 3;
            font-size: 14px;
          }
        }
      }
    }
  }
}

</style>