<template>
  <div class="content" v-if="userData">
    <the-header v-if="userData" :userData="userData"></the-header>
    <Toast />
    <div class="content_block">
        <h1 class="content_block_title">Правила</h1>
        <div class="widget widget_rules">

        <h3>Что такое Старта.Маркет?</h3>
        <p>Старта.Маркет &ndash; это корпоративный маркетплейс привилегий, созданный чтобы мотивации тебя и твоих коллег, а также сделать работу интереснее и веселее!</p>
        <p>Выполняя задания, ты можешь зарабатывать внутреннюю валюту — стартики, которые можно обменять на крутые награды из нашего каталога.</p>

        <h3>Что такое стартики?</h3>
        <p>Да, отныне можно зарабатывать не только рублики, но и стартики :)</p>
        <p>Стартики — это не просто какие-то абстрактные очки или баллы. Стартики &ndash; это наша внутренняя валюта, имеющая реальную ценность. Заработать их не так просто, поэтому каждый стартик &ndash; это признание твоих усилий и достижений.</p>
        <p>Стартики нельзя передавать другим или перевести в реальные деньги. Их можно использовать только в рамках нашего маркетплейса для покупки товаров и привилегий.</p>

        <h3>Как заработать стартики?</h3>
        <p>Заработать (или потерять!) стартики можно несколькими способами:</p>
        <ul>
            <li>За выполнение заданий из списка ниже;</li>
            <li>Руководство может начислить стартики за заслуги отличившимся сотрудникам по своему усмотрению.</li>
        </ul>

        <div class="table table_awards">
            <div class="theader">
                <div class="th">
                    <div class="td">№</div>
                    <div class="td">Задание</div>
                    <div class="td">Условия</div>
                    <div class="td">Ограничения</div>
                    <div class="td">Награда</div>
                </div>
            </div>
            <div class="tbody">
                <div class="tr" v-for="task in $store.state.tasks" :key="task.id">
                    <div class="td">{{task.id}}</div>
                    <div class="td">{{task.title}}</div>
                    <div class="td">{{task.condition}}</div>
                    <div class="td">{{task.limits}}</div>
                    <div class="td"><span>{{task.award}}</span></div>
                </div>
            </div>
        </div>
        <p>Обрати внимание на ограничения: например, за задание с ограничением «не более 1 награды в месяц» ты получаешь награду только один раз в месяц, даже если ты выполнишь его несколько раз.</p>
        <p>Стартики начисляются или списываются руководством по факту выполнения задания.</p>

        <h3>Как потратить стартики?</h3>
        <p>Все просто:</p>
        <ul>
            <li>Переходи в каталог;</li>
            <li>Выбирай понравившуюся награду;</li>
            <li>Читай описание и условия;</li>
            <li>Покупай!</li>
        </ul>
        <p>Обрати внимание на условия предоставления привилегий. Не все награды можно получить сразу, а у некоторых привилегий есть ограничения по времени использования.</p>

        <h3>Новинка! Командные покупки</h3>
        <p>Теперь ты можешь объединяться с коллегами для командных покупок дорогостоящих привилегий! Хотите новое кресло в релакс-зону, спортивный инвентарь для совместного отдыха или видеоприставку в офис? Скидывайтесь стартиками и приобретайте это вместе!</p>

        <h3>Обратная связь</h3>
        <p>Мы всегда рады обратной связи по маркетплейсу. Если у тебя есть предложения по новым привилегиям в каталоге или ты нашёл ошибки в работе сервиса, не стесняйся сообщать об этом своему руководителю!</p>

        <h3>Приятного пользования!</h3>
        <p>В общем, будь внимателен, копи стартики и трать их с умом. Пользуйся нашим маркетплейсом, чтобы сделать свою работу еще интереснее и приятнее!</p>
        <p>Заходи, выполняй задания, зарабатывай стартики и наслаждайся привилегиями!</p>
        </div>
    </div>
  </div>
</template>

<script>
import UserService from "../data/UserService";

export default {
  data() {
    return {
      userData: null
    }
  },
  UserService: null,
  created() {
    this.UserService = new UserService();
  },
  mounted() {
    this.checkAuthorization();
  },
  methods: {
    checkAuthorization() {
      const that = this;
      this.UserService.checkAuth().then(data => {
        if (data.status) {
          that.userData = data.message;
        } else {
          that.$toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: data.message,
            life: 4000
          });
          that.$router.push('/auth');
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>

.widget_rules {
    img {
        max-width: 100%;
    }
    p {
        margin-bottom: 10px;
    }
}
.table {
    width: 100%;
    margin: 30px 0;
    .th,
    .tr {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .td {
        padding: 8px 10px;
        border-width: 1px;
        border-style: solid;
        display: flex;
        border-color: white;
    }
    .theader {
        .th {
            background: #e0e0e0;
            align-items: stretch;
            .td {
                padding: 10px;
                font-size: 18px;
                text-align: center;
                font-weight: 600;
                border-color: white;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .tbody {
        .tr {
            &:nth-of-type(even) {
                background-color: #f8f8f8;
            }
            &:hover {
                background-color: #fafafa;
            }
        }
    }
    &_awards {
        .td {
            &:nth-of-type(1) {
                flex: 0 0 5%;
                justify-content: center;
            }
            &:nth-of-type(2) {
                flex: 0 0 30%;
            }
            &:nth-of-type(3) {
                flex: 0 0 35%;
            }
            &:nth-of-type(4) {
                flex: 0 0 20%;
            }
            &:nth-of-type(5) {
                flex: 0 0 10%;
                justify-content: center;
                span {
                    position: relative;
                    font-weight: 600;
                    &:after {
                        position: absolute;
                        content: '';
                        display: table;
                        width: 10px;
                        height: 18px;
                        background: url("../assets/img/s_coin.svg") no-repeat center;
                        background-size: contain;
                        left: calc(100% + 1px);
                        opacity: .8;
                        top: 0;
                    }
                }
            }
        }
        @media screen and (max-width: 640px) {
            .theader {
                .th {
                    .td {
                        font-size: 14px;
                    }
                }
            }
            .td {
                font-size: 14px;
                padding: 6px 8px;
                // &:nth-of-type(1) {
                //     width: 40px;
                //     justify-content: center;
                // }
                // &:nth-of-type(2) {
                //     flex: 0 0 calc(100% - 140px);
                //     width: auto;
                //     max-width: auto;
                // }
                // &:nth-of-type(3) {
                //     width: 100px;
                //     justify-content: center;
                //     font-size: 16px;
                // }
            }
        }
        @media screen and (max-width: 480px) {
            .theader {display: none;}
            .tr {
                // flex-wrap: wrap;
                margin-bottom: 10px;
            }
            .td {
                border: none;
                // &:nth-of-type(1) {display: none;}
                // &:nth-of-type(2) {
                //     //flex: 0 0 calc(100% - 100px);
                //     flex: 0 0 85%;
                // }
                // &:nth-of-type(3) {
                //     flex: 0 0 15%;
                //     font-size: 18px;
                //     align-items: center;
                //     span {
                //         &:after {
                //             width: 11px;
                //             height: 22px;
                //         }
                //     }
                // }
            }
        }
    }
}
</style>