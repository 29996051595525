import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import Catalog from '../views/Catalog.vue';
import Common from '../views/Common.vue';
import Product from '../views/Product.vue';
import CommonProduct from '../views/CommonProduct.vue';
import Profile from '../views/Profile.vue';
import User from '../views/User.vue';
import History from '../views/History.vue';
import Orders from '../views/Orders.vue';
import Rules from '../views/Rules.vue';
import Admin from '../views/Admin.vue';
import Users from '../views/admin/Users.vue';
import Transactions from '../views/admin/Transactions.vue';
import Auth from '../views/Auth.vue';
import NotFound from '../views/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Главная',
    component: Dashboard,
    meta: {title: 'Главная'}
  },
  {
    path: '/catalog',
    name: 'Каталог',
    component: Catalog,
    meta: {title: 'Каталог'}
  },
  {
    path: '/common',
    name: 'Командные покупки',
    component: Common,
    meta: {title: 'Командные покупки'}
  },
  {
    path: '/catalog/:id',
    component: Product,
    meta: {title: 'Каталог'}
  },
  {
    path: '/common/:id',
    component: CommonProduct,
    meta: {title: 'Командные покупки'}
  },
  {
    path: '/profile',
    name: 'Профиль',
    component: Profile,
    meta: {title: 'Мой профиль'}
  },
  {
    path: '/profile/:id',
    component: User,
    meta: {title: 'Пользователь'}
  },
  {
    path: '/history',
    name: 'История операций',
    component: History,
    meta: {title: 'История операций'}
  },
  {
    path: '/orders',
    name: 'Мои заказы',
    component: Orders,
    meta: {title: 'Мои заказы'}
  },
  {
    path: '/rules',
    name: 'Правила программы',
    component: Rules,
    meta: {title: 'Правила программы'}
  },
  {
    path: '/admin',
    name: 'Управление',
    component: Admin,
    meta: {title: 'Управление'}
  },
  {
    path: '/admin/users',
    name: 'Управление пользователями',
    component: Users,
    meta: {title: 'Управление пользователями'}
  },
  {
    path: '/admin/transactions',
    name: 'Управление транзакциями',
    component: Transactions,
    meta: {title: 'Управление транзакциями'}
  },
  {
    path: '/auth',
    name: 'Авторизация',
    component: Auth,
    meta: {title: 'Авторизация'}
  },
  {
    path: '/:notFound(.*)',
    component: NotFound,
    meta: {title: 'Страница не найдена'}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})





export default router
