<template>
  <div class="content" v-if="userData">
    <the-header v-if="userData" :userData="userData"></the-header>
    <div class="content_block">
      <h1 class="content_block_title">Каталог</h1>
      <widget-catalog-listing v-if="userData" :userData="userData"></widget-catalog-listing>
    </div>
  </div>
</template>

<script>
import WidgetCatalogListing from "../layout/ui/WidgetCatalogListing.vue";
import UserService from "../data/UserService";

export default {
  data() {
    return {
      userData: null,
    };
  },
  UserService: null,
  components: {
    "widget-catalog-listing": WidgetCatalogListing
  },
  created() {
    this.UserService = new UserService();
  },
  mounted() {
    this.checkAuthorization();
  },
  methods: {
    checkAuthorization() {
      const that = this;
      this.UserService.checkAuth().then((data) => {
        if (data.status) {
          that.userData = data.message;
        } else {
          that.$toast.add({
            severity: "error",
            summary: "Ошибка",
            detail: data.message,
            life: 4000,
          });
          that.$router.push("/auth");
        }
      });
    },
  },
};
</script>